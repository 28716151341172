import React from "react";
import { Button } from 'antd';
import "./EmptyState.css";

import { ReactComponent as Bird } from "../../assets/bird.svg";

export const EmptyState = ({ text, buttonText, onClick }) => {
  const onClickHandle = () => {
    onClick && onClick()
  }

  return (
    <div className="EmptyStateContainerBox">
      <Bird />
      <span className="EmptyStateTitle">
        {text}
      </span>
      {onClick &&
        <Button
          size='small'
          type='default'
          iconPosition='end'
          onClick={onClickHandle}
          style={{ backgroundColor: '#D9F564' }}
          className={`EmptyStateButton uppercase`}>
          {buttonText}
        </Button>
      }
    </div>
  );
};