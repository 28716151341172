import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

import { humanizeTimestamp } from "react-activity-feed/dist/utils";

import { PostHeader } from "./PostHeader";
import "./Post.css";
import { PostFooter } from "./PostFooter";
import Comments from "./PostCommentList";
import { useNavigate } from "react-router-dom";
import { FEED_ID_USER, GetThreadUrlPath } from "../../routes";
import { Modal } from "antd";
import ProgressLoader from "../ProgressLoader";

export default function Post(props) {
  const {
    currentStreamUser,
    activity,
    setHeightToMainComponent,
    hideCommentButton,
    showComments,

    isFollowingUserId,
    followUser,
    unfollowUser,
  } = props;

  let container = useRef(null);

  const navigate = useNavigate();
  const [showImage, setShowImage] = useState(false);
  const [loadingImage, setLoadingImage] = useState(true);

  const postId = activity.id;
  const topic = activity?.topic?.toLowerCase() || FEED_ID_USER;

  const goToComments = () => {
    const path = GetThreadUrlPath({ feedId: topic, activityId: postId });

    navigate(path);
  };

  useEffect(() => {
    if (container) {
      setHeightToMainComponent &&
        setHeightToMainComponent(container?.current?.offsetHeight);
    }
  }, [container, setHeightToMainComponent]);

  if (!activity) return null;

  const { actor, title, text, time } = activity;

  const isFollowing = isFollowingUserId(activity.actor.id);

  const handleFinishLoading = () => {
    setLoadingImage(false);
  };

  const renderImage = () => {
    if (activity?.attachments?.images?.length > 0) {
      return (
        <>
          {loadingImage && (
            <div className="PostImageLoading">
              <ProgressLoader />
            </div>
          )}
          <div className="PostImageContainerDetails PostBorderWindow">
            <img
              src={`${activity.attachments.images[0].image_url}`}
              className="PostImageContainer"
              style={{
                display: loadingImage ? "none" : "block",
              }}
              onClick={() => setShowImage(true)}
              onLoad={handleFinishLoading}
              onError={handleFinishLoading}
            />
          </div>
        </>
      );
    }
  };

  return (
    <div className="PostContainer" ref={container}>
      <PostHeader
        currentStreamUser={currentStreamUser}
        actor={actor}
        author={actor?.data?.name}
        specialty={actor?.data?.specialty}
        specialtyIcon={actor?.data?.specialty_icon}
        yearsOfExperience={actor?.data?.years_of_experience}
        isFollowing={isFollowing}
        followUser={followUser}
        unfollowUser={unfollowUser}
      />
      <div className={`PostBody PostBorderWindow bg-white`}>
        <h1 className="PostTitle cursor-pointer" onClick={goToComments}>
          {title}
        </h1>
        <h3 className="PostContent">
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </h3>
        <span className="PostDate" onClick={goToComments}>
          {humanizeTimestamp(time, (time) => moment(time))}
        </span>
      </div>

      {renderImage()}

      <PostFooter
        currentStreamUser={currentStreamUser}
        actor={actor}
        activity={activity}
        postId={activity.id}
        hideCommentButton={hideCommentButton}
      />
      {showComments && <Comments activity={activity} />}
      <Modal
        footer={false}
        onCancel={() => setShowImage(false)}
        open={showImage}
      >
        {activity?.attachments?.images?.length > 0 && (
          <div className="pt-8">
            <img src={`${activity?.attachments?.images[0].image_url}`} />
          </div>
        )}
      </Modal>
    </div>
  );
}
