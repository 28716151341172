import { useStytch, useStytchUser } from "@stytch/react";
import { useEffect, useState, useCallback, useRef } from "react";
import * as Sentry from "@sentry/react";

import {
  AuthenticateRubieUser,
  GetUserStreamToken,
} from "../../services/RubieAuth";
import { Loading } from "../Loading/Loading";
import { LOGIN_PATH } from "../../routes";

const TokenAuthenticator = ({ children }) => {
  const stytch = useStytch();
  const { user } = useStytchUser();

  const [loading, setLoading] = useState(true);
  const [loginError, setLoginError] = useState(null);
  const authAttemptedRef = useRef(false);

  const AuthenticateRubieServer = useCallback(async () => {
    try {
      setLoading(true);
      await AuthenticateRubieUser();
      window.location.href = "/";
    } catch (err) {
      setLoginError(err);
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const authenticateToken = useCallback(
    async (token, tokenType) => {
      if (authAttemptedRef.current) return;
      authAttemptedRef.current = true;

      try {
        if (tokenType === "magic_links") {
          await stytch.magicLinks.authenticate(token, {
            session_duration_minutes: 263520,
          });
        } else if (tokenType === "oauth") {
          await stytch.oauth.authenticate(token, {
            session_duration_minutes: 263520,
          });
        } else {
          throw new Error("Invalid token type");
        }
        await AuthenticateRubieServer();
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
        setLoginError({
          message:
            "There was an error with your magic link. Please try logging in again to get a new one!",
        });
        setLoading(false);
      }
    },
    [stytch, AuthenticateRubieServer]
  );

  useEffect(() => {
    if (!stytch || user || authAttemptedRef.current) {
      // setLoading(false);

      if (authAttemptedRef.current) {
        // Auth request to Rubie server has already been attempted
        return;
      }

      // If the user is already authenticated, but the Rubie server has not been authenticated, authenticate the Rubie server
      if (user) {
        GetUserStreamToken().then((streamToken) => {
          if (streamToken?.length) {
            window.location.href = "/";
          } else {
            AuthenticateRubieServer();
          }
        });
      }

      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const tokenType = queryParams.get("stytch_token_type");

    if (token && tokenType) {
      setLoading(true);
      authenticateToken(token, tokenType);
    } else {
      setLoading(false);
    }
  }, [stytch, user, authenticateToken, AuthenticateRubieServer, setLoading]);

  if (loading) {
    return (
      <div className="flex justify-center">
        <Loading />
      </div>
    );
  }

  if (loginError) {
    return (
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <strong className="font-bold">Error logging in: </strong>
        <span className="block sm:inline">
          {loginError.message || "An unexpected error occurred."}
        </span>
        <p className="mt-2">
          <button
            onClick={() => (window.location.href = LOGIN_PATH)}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Try Again
          </button>
        </p>
      </div>
    );
  }

  return children;
};

export default TokenAuthenticator;
