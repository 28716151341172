import React, { useState } from "react";
import { Button, Input, message } from "antd";
import "./Invite.css";
import { ReactComponent as InviteActivated } from "../../assets/InviteActivated.svg";
import { ReactComponent as InviteDisabled } from "../../assets/InviteDisabled.svg";
import { RubieButton } from "../RubieButton";
import { ReactComponent as Share } from "../../assets/Export.svg";
import { UnlockInviteModal } from "./UnlockInviteModal";
import { useSwipeable } from "react-swipeable";
import { ShareInviteModal } from "./ShareInviteModal";
import { useMutation } from "@apollo/client";
import { CREATE_INVITE_MUTATION } from "../../graphql/user";

export const InviteBox = ({ currentUser, refreshParent }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openShareDrawer, setOpenShareDrawer] = useState(false);
  const [invite, setInvite] = useState(null);
  const [recipientName, setRecipientName] = useState("");

  const handlers = useSwipeable({
    onSwiped: () => setOpenDrawer(false),
  });

  const [createInvite, { loading: createInviteLoading }] = useMutation(
    CREATE_INVITE_MUTATION,
    {
      onCompleted: (data) => {
        if (data.createInvite.invite) {
          setInvite(data.createInvite.invite);
          setOpenShareDrawer(true);
          setRecipientName("");
          if (refreshParent) {
            refreshParent();
          }
        } else {
          message.error(data.createInvite.errors[0]);
        }
      },
      onError: (error) => {
        message.error("Failed to create invite. Please try again.");
      },
    }
  );

  const onSendInviteHandle = () => {
    if (recipientName) {
      createInvite({ variables: { recipientName } });
    } else {
      message.error("Please enter a name.");
    }
  };

  const onEarnMoreHandle = () => {
    setOpenDrawer(true);
  };

  const remainingInvites = currentUser?.remainingInvites || 0;

  return (
    <>
      <div className="InviteContainerBox">
        <div className="InviteImageContainer">
          <div className="InviteBackground">
            {remainingInvites === 0 ? (
              <InviteDisabled width={"100%"} />
            ) : (
              <InviteActivated width={"100%"} />
            )}
          </div>
          <div className="InviteButtonContainer">
            <RubieButton
              type="ghost"
              className={"InviteCountButton"}
              disabled={remainingInvites === 0}
            >
              <span style={{ fontSize: 10 }}>{`${remainingInvites} invite${
                remainingInvites !== 1 ? "s" : ""
              }`}</span>
            </RubieButton>
            {/* <Button
              size="small"
              type="default"
              iconPosition="end"
              className={`InviteEarnMoreButton`}
              onClick={onEarnMoreHandle}
            >
              EARN MORE
            </Button> */}
          </div>
        </div>
        <span className="InviteTitle">
          {remainingInvites === 0
            ? "🥺 All Out of Invites!"
            : "You've unlocked a VIP ticket! 🎟️"}
        </span>
        <span className="InviteDescription">
          {remainingInvites === 0
            ? "Thanks for sharing the love!"
            : "Share Rubie with your friends!"}
        </span>
        <Input
          type="text"
          value={recipientName}
          onChange={(e) => setRecipientName(e.target.value)}
          placeholder="Enter friend's name"
          className="p-2 border rounded-lg w-1/2 mt-4"
          disabled={remainingInvites === 0 || createInviteLoading}
        />
        <Button
          size="small"
          type="default"
          disabled={remainingInvites === 0 || createInviteLoading}
          iconPosition="start"
          icon={<Share />}
          onClick={onSendInviteHandle}
          style={{ backgroundColor: "#FAF4DA" }}
          className={`CopyButton uppercase mt-6 flex items-center pt-2 pb-2`}
        >
          {createInviteLoading ? "Creating..." : "Send Invite"}
        </Button>
      </div>
      <UnlockInviteModal
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        handlers={handlers}
      />
      <ShareInviteModal
        setOpenDrawer={setOpenShareDrawer}
        openDrawer={openShareDrawer}
        handlers={handlers}
        invite={invite}
      />
    </>
  );
};
