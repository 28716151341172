import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CLAIM_INVITE_MUTATION } from "../graphql/user";
import { Input, message, Form } from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import { RubieButton } from "../components/RubieButton";
import PublicLayout from "../layoutsHOC/PublicLayout";
import handShake from "../assets/handShake.png";
import { SignUpFooterText } from "./SignUp/components/SignupFooter";

const ClaimInvite = () => {
  const [form] = Form.useForm();
  const [code, setCode] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("code") || "";
  });
  const [inviteClaimed, setInviteClaimed] = useState(false);

  const [claimInvite, { loading }] = useMutation(CLAIM_INVITE_MUTATION, {
    onCompleted: (data) => {
      if (data?.claimInvite?.success) {
        message.success("Invite claimed successfully!");
        setInviteClaimed(true);
      } else {
        message.error("Failed to claim invite. Please try again.");
      }
    },
    onError: (error) => {
      message.error(`Error: ${error.message}`);
    },
  });

  const handleSubmit = async (values) => {
    if (!values.code || !values.email) {
      message.error("Please enter both invite code and email.");
      return;
    }
    try {
      await claimInvite({
        variables: { code: values.code, email: values.email },
      });
    } catch (err) {
      console.error("Error claiming invite:", err);
    }
  };

  const title = (
    <>
      <img width={40} src={handShake} alt="Shake Hands" /> Claim Your Invite
    </>
  );

  const subtitle = "Join the Rubie community with your exclusive invite.";

  return (
    <PublicLayout title={title} subtitle={subtitle}>
      {inviteClaimed ? (
        <div style={{ textAlign: "center" }}>
          <h3 className="text-xl font-bold mb-4">You're almost there!</h3>
          <p className="mb-4 text-center">
            Please check your email
            <br />
            <i>{form.getFieldValue("email")}</i>
            <br /> to finish claiming your invite.
          </p>
        </div>
      ) : (
        <Form
          form={form}
          name="claim_invite"
          initialValues={{ code }}
          onFinish={handleSubmit}
          layout="vertical"
          style={{ width: 330 }}
        >
          <Form.Item
            name="code"
            rules={[
              { required: true, message: "Please input your invite code!" },
            ]}
          >
            <Input placeholder="Enter your invite code" size="large" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input placeholder="Enter your email" size="large" />
          </Form.Item>

          <Form.Item>
            <RubieButton
              type="primary"
              htmlType="submit"
              loading={loading}
              block
              size="sm"
            >
              Claim Invite
            </RubieButton>
          </Form.Item>

          <SignUpFooterText />
        </Form>
      )}
    </PublicLayout>
  );
};

export default ClaimInvite;
