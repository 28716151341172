import { Select } from "antd";

const CompleteProfileStep6 = ({ facility, setFacility }) => {
  return (
    <>
      <div className="CompleteProfileForm">
        <label>Current Facility</label>
        <Select
          style={{ width: "100%" }}
          value={facility}
          onChange={setFacility}
          placeholder="Select Current Facility"
          size="large"
          options={[
            {
              label: "Conference Room",
              value: "Conference Room",
            },
          ]}
        />
      </div>
    </>
  );
};

export default CompleteProfileStep6;
