import React, { useEffect, useState } from "react";
import "./ProfileHeader.css";
import { Button } from "@nextui-org/react";
import { Avatar } from "../Avatar/Avatar";
import { ReactComponent as SettingsIcon } from "../../assets/Settings.svg";
import { SETTINGS_PATH } from "../../routes";
import { useNavigate } from "react-router-dom";
import { isNil, repeat } from "lodash";
import { getFirstAndLastName } from "../../helpers/user";
import { FollowButton } from "../FollowButton/FollowButton";
import { ReactComponent as CaretCircle } from "../../assets/CaretCircle.svg";
import { goBack } from "../../helpers/navigation";

export function ProfileHeader({
  userId,
  name,
  specialty,
  specialtyIcon,
  yearsOfExperience,
  isCurrentUser,
  isFollowing,
  followUser,
  unfollowUser,
}) {
  const navigate = useNavigate();

  const [titleBackground, setBackgroundTitle] = useState();
  const [loading, setLoading] = useState(false);

  // TODO: get this from a mapping based on specialty string
  const back = () => {
    goBack(navigate);
  };

  useEffect(() => {
    if (!isNil(name)) {
      setBackgroundTitle(repeat(" • " + name, 50));
    }
  }, [name]);

  const onFollowClick = async () => {
    setLoading(true);
    if (followUser) {
      await followUser(userId);
    }
    setLoading(false);
  };

  const onUnfollowClick = async () => {
    setLoading(true);
    if (unfollowUser) {
      await unfollowUser(userId);
    }
    setLoading(false);
  };

  const renderFollowButton = () => {
    if (isCurrentUser) {
      return null;
    }

    return isFollowing ? (
      <FollowButton
        isFollowing={isFollowing}
        loading={loading}
        onClick={onUnfollowClick}
        text="Following"
      />
    ) : (
      <FollowButton
        isFollowing={isFollowing}
        loading={loading}
        onClick={onFollowClick}
        text="Follow"
      />
    );
  };

  return (
    <>
      {!isCurrentUser && (
        <div className="ProfileHeaderCardHeaderContainer">
          <div className="ProfileHeaderCardClose">
            <div onClick={back} className="cursor-pointer">
              <CaretCircle className="ProfileHeaderCaretLeft" /> BACK
            </div>
          </div>
          <div className="ProfileHeaderCardTitleDiv" />
        </div>
      )}
      <div className="ProfileHeaderContainer">
        <div className="ProfileHeaderCardTitleDiv">
          <div className="ProfileAvatar">
            <Avatar backgroundColor="#FFC3FE" name={name} />
          </div>
          <div className="ProfileHeaderCardBackgroundTitle">
            <span>{titleBackground}</span>
          </div>
        </div>
        <div className="ProfileHeaderTextContainer pt-1">
          {isCurrentUser ? (
            <Button
              className="self-end"
              onClick={() => navigate(SETTINGS_PATH)}
              radius="none"
              color="transparent"
              startContent={
                <div className="border border-black p-1 rounded-full bg-white">
                  <SettingsIcon />
                </div>
              }
            />
          ) : (
            <div className="self-end pr-3 pt-1">{renderFollowButton()}</div>
          )}
          <div className="flex items-center">
            <div className="ProfileHeaderTitle">
              {getFirstAndLastName(name)}
            </div>
          </div>
          <span className="ProfileHeaderDescription">
            {specialtyIcon && <span className="mr-2">{specialtyIcon}</span>}
            {specialty && <span className="mr-2">{specialty}</span>}
            {!isNil(yearsOfExperience) && (
              <>
                {specialty && (
                  <>
                    <span className="mr-2">|</span>{" "}
                  </>
                )}
                <span>
                  {yearsOfExperience ? yearsOfExperience + " Years" : null}
                </span>
              </>
            )}
          </span>
        </div>
      </div>
    </>
  );
}
