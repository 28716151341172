import React, { useEffect, useState } from "react";
import "./Avatar.css";
import { ReactComponent as AvataIcon } from "../../assets/Avatar.svg";
import { getInitialLetters } from "../../helpers/user";
import { isNil } from "lodash";

export function Avatar({ name, size, backgroundColor, dynamicSize, onClick }) {
  const [width, setWidth] = useState();
  const [fontSize, setFontSize] = useState();
  useEffect(() => {
    if (isNil(dynamicSize)) {
      if (size == null || size === "default") {
        setWidth(70);
        setFontSize(28);
      } else if (size === 'large') {
        setWidth(96);
        setFontSize(32);
      } else if (size === 'medium') {
        setWidth(40);
        setFontSize(16);
      } else if (size === "small") {
        setWidth(32);
        setFontSize(12);
      } else if (size === 'extra-small') {
        setWidth(18);
        setFontSize(8);
      }
    } else {
      setWidth(dynamicSize.width);
      setFontSize(dynamicSize.fontSize);
    }
  }, [size, dynamicSize]);

  const handleClick = () => {
    onClick && onClick();
  }

  return (
    <div style={{ fontSize: width, width: width }} className="AvatarContainer" onClick={handleClick}>
      <AvataIcon style={{ color: backgroundColor }}></AvataIcon>
      <div style={{ fontSize: fontSize }} className="AvatarName">
        {getInitialLetters(name)}
      </div>
    </div>
  );
}
