import React from "react";
import confetti from "../../../assets/confetti.svg";
import { Avatar } from "../../../components/Avatar";
import { ReloadOutlined } from "@ant-design/icons";

export function SignUpStep4({ user, resetForm }) {
  return (
    <div className="SignUpStep">
      <img width={40} className="SignUpStepImg" src={confetti} alt="Confetti" />
      <div className="SignUpStepTitle">We found you!</div>
      <div className="SignUpStepDescription">
        Almost done! Please review your information before continuing.
      </div>
      <div className="SignUpCard">
        <div className="SignUpCardHeader">
          <div className="SignUpCardHeaderText">
            <Avatar
              size="medium"
              name="Registered Nurse"
              backgroundColor="#FFC3FD"
            />{" "}
            Registered Nurse
          </div>
        </div>
        <div className="SignUpCardBody">
          <div className="SignUpCardFullName">
            {user.firstName} {user.lastName}
          </div>
          <div className="SignUpCardInfo">
            {user.validateWith === "1" && (
              <>
                <div className="SignUpCardInfoItem">
                  <h3>License Number</h3>
                  <h4>{user.licenseNumber}</h4>
                </div>
                <div className="SignUpCardInfoItem">
                  <h3>License Type</h3>
                  <h4>{user.licenseType}</h4>
                </div>
                <div className="SignUpCardInfoItem SignUpCardInfoSingleItem">
                  <h3>State</h3>
                  <h4>{user.licenseState}</h4>
                </div>
              </>
            )}
            {user.validateWith === "2" && (
              <>
                <div
                  className={`SignUpCardInfoItem ${
                    !user.licenseType && !user.licenseState
                      ? "SignUpCardInfoSingleItem"
                      : ""
                  }`}
                >
                  <h3>NCSBN ID</h3>
                  <h4>{user.ncsbnId}</h4>
                </div>
                {user.licenseType && (
                  <div className={`SignUpCardInfoItem`}>
                    <h3>License Type</h3>
                    <h4>{user.licenseType}</h4>
                  </div>
                )}
                {user.licenseState && (
                  <div
                    className={`SignUpCardInfoItem ${
                      user.licenseType ? "SignUpCardInfoSingleItem" : ""
                    }`}
                  >
                    <h3>State</h3>
                    <h4>{user.licenseState}</h4>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="SignUpCardInfoFooter">
            <div className="SignUpCardInfoFooterText">Status</div>
            <div className="SignUpCardInfoFooterStatus StatusActive">
              ACTIVE
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={resetForm}
        style={{ justifyContent: "flex-end", width: "100%", paddingTop: 18 }}
        className="SignUpStepQuestion"
      >
        NOT YOU? TRY AGAIN <ReloadOutlined />
      </div>
    </div>
  );
}
