import React, { useState, useEffect } from "react";
import { FlatFeed, useStreamContext } from "react-activity-feed";
import { useNavigate, useParams } from "react-router-dom";
import { isNil } from "lodash";

import { AuthenticatedLayout } from "../layoutsHOC/AuthenticatedLayout";
import Post from "../components/Post";
import ProgressLoader from "../components/ProgressLoader";
import InfiniteScrollPaginator from "../components/InfiniteScrollPaginator";
import {
  CREATE_POST_PATH,
  CompareUrlPath,
  DISCOVER_PATH,
  FEED_ID_DISCOVER,
  FEED_ID_TIMELINE,
  FeedGroupAndGroupIdFromFeedId,
  TIMELINE_PATH,
} from "../routes";
import { SlideTabs } from "../components/Tabs/Tabs";
import { useStreamFollowers } from "../services/StreamFollowers";
import { EmptyPostState } from "../components/Post/EmptyPost";

function TimelinePageInner({ props }) {
  const navigate = useNavigate();
  let { feedId, externalUserId } = useParams();

  const { user } = useStreamContext();

  const [tabIndex, setTabIndex] = useState(0);

  const urlPath = window.location.pathname;

  useEffect(() => {
    if (CompareUrlPath(urlPath, TIMELINE_PATH)) {
      setTabIndex(1);
    }
  }, [urlPath]);

  const { followUser, unfollowUser, isFollowingUserId } = useStreamFollowers();

  const { feedGroup, feedGroupId } = FeedGroupAndGroupIdFromFeedId({
    feedId,
    externalUserId,
    user,
  });

  const tabOptions = [
    { label: "Discover", value: FEED_ID_DISCOVER },
    { label: "Following", value: FEED_ID_TIMELINE },
  ];

  const onTabSelect = (index) => {
    setTabIndex(index);

    if (index === 0) {
      navigate(DISCOVER_PATH);
    } else {
      navigate(TIMELINE_PATH);
    }
  };

  return (
    <>
      {(isNil(feedId) ||
        [FEED_ID_TIMELINE, FEED_ID_DISCOVER].includes(feedId)) && (
        <SlideTabs
          options={tabOptions}
          selectedIndex={tabIndex}
          onClick={onTabSelect}
        />
      )}
      <FlatFeed
        notify
        feedGroup={feedGroup}
        userId={feedGroupId}
        options={{
          limit: 5,
          withOwnReactions: true,
          withReactionCounts: true,
        }}
        Placeholder={
          <EmptyPostState
            text="POST SOMETHING"
            onClick={() => navigate(CREATE_POST_PATH)}
          />
        }
        LoadingIndicator={ProgressLoader}
        Paginator={InfiniteScrollPaginator}
        Activity={({ activity }) => (
          <Post
            currentStreamUser={user}
            key={activity?.id}
            activity={activity}
            isFollowingUserId={isFollowingUserId}
            followUser={followUser}
            unfollowUser={unfollowUser}
          />
        )}
      />
    </>
  );
}

export default function TimelinePage(props) {
  return (
    <AuthenticatedLayout {...props} backgroundColor="#FBFAF2">
      <TimelinePageInner {...props} />
    </AuthenticatedLayout>
  );
}
