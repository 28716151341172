import moment from "moment";

export const formatDate = (date) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  if (isSameDay(date, today)) {
    return "TODAY";
  } else if (isSameDay(date, yesterday)) {
    return "YESTERDAY";
  } else {
    return moment(date).format("YYYY-MM-DD");
  }
};
export const formatRelativeTime = (date) => {
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  const absDiff = Math.abs(diff);
  const seconds = Math.floor(absDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const years = Math.floor(days / 365);

  const formatTime = (value, unit) => {
    const plural = value > 1 ? "s" : "";
    return `${value} ${unit}${plural} ${diff >= 0 ? "from now" : "ago"}`;
  };

  if (seconds < 60) {
    return diff >= 0 ? "In a moment" : "Just now";
  } else if (minutes < 60) {
    return formatTime(minutes, "minute");
  } else if (hours < 24) {
    return formatTime(hours, "hour");
  } else if (days === 1) {
    return diff >= 0 ? "Tomorrow" : "Yesterday";
  } else if (days < 30) {
    return formatTime(days, "day");
  } else if (days < 365) {
    const months = Math.floor(days / 30);
    return formatTime(months, "month");
  } else {
    return formatTime(years, "year");
  }
};

export function calculateRegistrationTime(registrationDate) {
  const now = new Date();
  const diffMilliseconds = now - registrationDate;
  const diffSeconds = Math.floor(diffMilliseconds / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffDays / 365);

  if (diffSeconds < 60) {
    return diffSeconds + (diffSeconds === 1 ? " second" : " seconds");
  } else if (diffMinutes < 60) {
    return diffMinutes + (diffMinutes === 1 ? " minute" : " minutes");
  } else if (diffHours < 24) {
    return diffHours + (diffHours === 1 ? " hour" : " hours");
  } else if (diffDays < 31) {
    return diffDays + (diffDays === 1 ? " day" : " days");
  } else if (diffMonths < 12) {
    return diffMonths + (diffMonths === 1 ? " month" : " months");
  } else {
    return diffYears + (diffYears === 1 ? " year" : " years");
  }
}
