import { Radio } from "antd";
import { ReactComponent as Medicine } from "../../assets/Medicine.svg";
import { ReactComponent as Suitcase } from '../../assets/Suitcase.svg';

const CompleteProfileStep3 = ({ role, setRole }) => {

  return (<div className="CompleteProfileRole">
    <div className={`CompleteProfileStepRadioItem ${role === 1 ? 'CompleteProfileStepRadioItemSelected' : ''}`} onClick={() => setRole(1)}>
      <Suitcase />
      <div className="CompleteProfileStepRadioItemTitle">
        I'm a Leader
      </div>
      <div className="CompleteProfileStepRadioItemDescription">
        Management, Education, Administration
      </div>
    </div>
    <div className={`CompleteProfileStepRadioItem ${role === 2 ? 'CompleteProfileStepRadioItemSelected' : ''}`} onClick={() => setRole(2)} >
      <Medicine />
      <div className="CompleteProfileStepRadioItemTitle">
        I'm a Caregiver
      </div>
      <div className="CompleteProfileStepRadioItemDescription">
        Direct Patient Care, Supportive Functions
      </div>
    </div>
  </div>)
}

export default CompleteProfileStep3;