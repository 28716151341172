import React, { useState } from "react";
import "./AccountVerifyHeader.css";
import { ReactComponent as CaretCircle } from "../../../assets/CaretCircle.svg";
import { useNavigate } from "react-router-dom";
import { repeat } from "lodash";

export function AccountVerifyHeader({ step, setStep }) {
  const navigate = useNavigate();

  const [titleBackground] = useState(repeat(" • Create an account", 50));

  const back = () => {
    if (step === 1) {
      navigate("/");
    } else {
      setStep((x) => x - 1);
    }
  };

  return (
    <div className="AccountVerifyHeaderContainer">
      <div className="AccountVerifyHeaderCardHeaderContainer">
        {step === 1 ? null : (
          <div className="AccountVerifyHeaderCardClose">
            <div onClick={back}>
              <CaretCircle className="AccountVerifyHeaderCaretLeft" /> BACK
            </div>
          </div>
        )}
        <div className="AccountVerifyHeaderCardTitleDiv">
          <div className="AccountVerifyHeaderCardBackgroundTitle">
            <span>{titleBackground}</span>
          </div>
        </div>
      </div>
      <div className="AccountVerifyHeaderSteps">
        <div
          className={`AccountVerifyHeaderStep AccountVerifyHeaderStepActived`}
        ></div>
        <div
          className={`AccountVerifyHeaderStep ${
            step > 1 ? "AccountVerifyHeaderStepActived" : ""
          }`}
        ></div>
        <div
          className={`AccountVerifyHeaderStep ${
            step > 2 ? "AccountVerifyHeaderStepActived" : ""
          }`}
        ></div>
        <div
          className={`AccountVerifyHeaderStep ${
            step > 3 ? "AccountVerifyHeaderStepActived" : ""
          }`}
        ></div>
      </div>
    </div>
  );
}
