import React, { useCallback, useEffect, useState } from "react";
import { Drawer, Modal } from "antd";
import { useDeviceType } from "../../hooks/useDeviceType";
import "./CompleteProfile.css";
import CompleteProfileStep1 from "./CompleteProfileStep1";
import { ReactComponent as CaretRight } from "../../assets/CaretRight.svg";
import CompleteProfileStep2 from "./CompleteProfileStep2";
import CompleteProfileStep3 from "./CompleteProfileStep3";
import CompleteProfileStep4 from "./CompleteProfileStep4";
import CompleteProfileStep5 from "./CompleteProfileStep5";
import CompleteProfileStep6 from "./CompleteProfileStep6";
import { useStreamContext } from "react-activity-feed";
import { GreetingsProfileDialog } from "../Dialog/GreetingsProfileDialog";
import { RubieUserService } from "../../services/RubieUser";
import { Loading } from "../Loading/Loading";

export const CompleteProfileModal = ({ setOpenDrawer, openDrawer, handlers }) => {
  const deviceType = useDeviceType();
  const [step, setStep] = useState(1);
  const [disabledNext, setDisabledNext] = useState(false);
  const [role, setRole] = useState();
  const [specialty, setSpecialty] = useState();
  const [yearsExperience, setYearsExperience] = useState();
  const [facility, setFacility] = useState();
  const { user } = useStreamContext();
  const [showGreetingsNotification, setShowGreetingsNotification] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStep(1);
      setRole(undefined);
      setSpecialty(undefined);
    }, 100);
  }, [openDrawer]);

  useEffect(() => {
    if (showGreetingsNotification)
      setTimeout(() => setShowGreetingsNotification(false), 2000);
  }, [showGreetingsNotification]);

  useEffect(() => {
    if (step === 1 && role == null) {
      setDisabledNext(true);
    } else if (step === 2 && specialty == null) {
      setDisabledNext(true);
    } else if (step === 3 && yearsExperience == null) {
      setDisabledNext(true);
    } else if (step === 4 && facility == null) {
      setDisabledNext(true);
    }
    // else {
    //   setDisabledNext(false);
    // }
    else if (step === 5) {
      saveData();
    } else {
      setDisabledNext(false);
    }
  }, [step, role, specialty, yearsExperience, facility, setOpenDrawer, setShowGreetingsNotification])

  const saveData = async () => {
    const gqlValues = {};

    if (specialty) {
      gqlValues.specialty = specialty;
    }

    if (role) {
      gqlValues.clinicalRoleType =
        parseInt(role) === 1 ? "Leader" : "Caregiver";
    }

    // if (employmentType) {
    //   gqlValues.employmentType = employmentType;
    // }

    if (yearsExperience) {
      const nursingStartedYear = (new Date()).getFullYear() - yearsExperience;
      gqlValues.startedNursingDate = (new Date(nursingStartedYear, 1, 1)).toISOString();
    }

    const resultProfile = await RubieUserService.updateWorkProfile(gqlValues);

    const resultLocation = await RubieUserService.updateLocation({
      currentCity: user.currentCity || "",
      currentState: user.currentState || "",
      currentFacility: facility,
    });

    if (resultProfile.errors?.length > 0 && resultLocation.errors?.length > 0) {
      setOpenDrawer(false);
      setTimeout(() => {
        setShowGreetingsNotification(true);
      }, 200);
    } else {

    }
  }

  const getTitle = useCallback(() => {
    // if (step === 1) {
    //   return 'Enhance your Profile with LinkedIn';
    // } else if (step === 2) {
    //   return 'You look great today, Upload Profile Picture!';
    // } else 
    if (step === 1) {
      return 'What’s your type of role?';
    } else if (step === 2) {
      return 'What’s your current specialty?';
    } else if (step === 3) {
      return 'How many years of experience do you have in nursing?';
    } else if (step === 4) {
      return <>
        Where are you working now?
        {user.currentCity && user.currentState && <div className='CompleteProfileDrawerSubtitle'>
          {`You mentioned you’re in <u>${user.currentCity}, ${user.currentState}.</u>`}
        </div>}
      </>;
    }
  }, [step]);

  if (deviceType === 'Mobile') {
    return (
      <>
        <Drawer style={{ borderRadius: '50px 50px 0 0 ' }} styles={{ body: { padding: 0 }, wrapper: { boxShadow: 'initial' } }} height={'auto'}
          onClose={() => setOpenDrawer(false)} closeIcon={false} open={openDrawer} placement='bottom'>
          <div className='CompleteProfileDrawer' {...handlers}>
            <div className='CompleteProfileDrawerGrabberBar' />
            <CompleteProfileStepsHeader step={step} />
            <div className='CompleteProfileDrawerFlex'>
              <div className='CompleteProfileDrawerTitle'>{getTitle()}</div>
            </div>
            <CompleteProfileStepsBody
              role={role}
              setRole={setRole}
              speciality={specialty}
              setSpeciality={setSpecialty}
              step={step}
              yearsExperience={yearsExperience}
              setYearsExperience={setYearsExperience}
              facility={facility}
              setFacility={setFacility}
            />
            <CompleteProfileStepsFooter disabledNext={disabledNext} setOpenDrawer={setOpenDrawer} setStep={setStep} />
          </div>
        </Drawer>
        <GreetingsProfileDialog
          userName={user?.data?.name}
          displayDialog={showGreetingsNotification}
          setDisplayDialog={setShowGreetingsNotification}
        />
      </>
    );
  }

  return (
    <Modal title={<div className='CompleteProfileDrawerTitle'>{getTitle()}</div>} footer={false}
      onCancel={() => setOpenDrawer(false)} open={openDrawer}>
      <div className='CompleteProfileDrawer'>
        <CompleteProfileStepsHeader step={step} />
        <CompleteProfileStepsBody role={role}
          setRole={setRole}
          speciality={specialty}
          setSpeciality={setSpecialty}
          step={step}
          yearsExperience={yearsExperience}
          setYearsExperience={setYearsExperience}
          facility={facility}
          setFacility={setFacility} />
        <CompleteProfileStepsFooter disabledNext={disabledNext} setOpenDrawer={setOpenDrawer} setStep={setStep} />
      </div>
    </Modal>
  )
}

const CompleteProfileStepsHeader = ({ step }) => {
  return (
    <div className='CompleteProfileHeaderSteps'>
      <div className={`CompleteProfileHeaderStep CompleteProfileHeaderStepActived`}></div>
      <div className={`CompleteProfileHeaderStep ${step > 1 ? 'CompleteProfileHeaderStepActived' : ''}`}></div>
      <div className={`CompleteProfileHeaderStep ${step > 2 ? 'CompleteProfileHeaderStepActived' : ''}`}></div>
      <div className={`CompleteProfileHeaderStep ${step > 3 ? 'CompleteProfileHeaderStepActived' : ''}`}></div>
      <div className={`CompleteProfileHeaderStep ${step > 4 ? 'CompleteProfileHeaderStepActived' : ''}`}></div>
      {/* <div className={`CompleteProfileHeaderStep ${step > 5 ? 'CompleteProfileHeaderStepActived' : ''}`}></div> */}
    </div>
  )
}

const CompleteProfileStepsBody = ({ step, role, setRole, speciality, setSpeciality, yearsExperience, setYearsExperience, facility, setFacility }) => {
  return (
    <div className='CompleteProfileSteps'>
      {/* {step === 1 && <CompleteProfileStep1 />} */}
      {/* {step === 2 && <CompleteProfileStep2 />} */}
      {step === 1 && <CompleteProfileStep3 role={role} setRole={setRole} />}
      {step === 2 && <CompleteProfileStep4 speciality={speciality} setSpeciality={setSpeciality} />}
      {step === 3 && <CompleteProfileStep5 speciality={yearsExperience} setYearsExperience={setYearsExperience} />}
      {step === 4 && <CompleteProfileStep6 facility={facility} setFacility={setFacility} />}
      {step === 5 && <Loading />}
    </div>
  )
}

const CompleteProfileStepsFooter = ({ setOpenDrawer, setStep, disabledNext }) => {
  return (
    <div className='CompleteProfileFooter'>
      <div onClick={() => setOpenDrawer(false)}>I’ll do it later</div>
      <div className={disabledNext ? 'disabled' : ''} onClick={() => !disabledNext && setStep(x => {
        return x + 1;
      })}>Next <CaretRight /></div>
    </div>
  )
}
