import { gql } from "@apollo/client";

export const CURRENT_USER_FIELDS = gql`
  fragment CurrentUserFields on User {
    id
    firstName
    lastName
    email

    isBanned
    accountVerified
    accountVerifiedAt

    specialty
    clinicalRole
    clinicalRoleType
    employmentType
    startedNursingDate
    currentCity
    currentState
    currentFacility

    licenseType
    licenseNumber
    licenseState
    ncsbnId
  }
`;

export const CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    currentUser {
      ...CurrentUserFields
    }
  }
  ${CURRENT_USER_FIELDS}
`;

// More lightweight query for auth checks
export const CURRENT_USER_AUTH_QUERY = gql`
  query GetCurrentUserAuth {
    currentUser {
      id
      email
      isBanned
      accountVerified
      accountVerifiedAt

      specialty
    }
  }
`;

export const GET_CURRENT_USER_WITH_INVITES_QUERY = gql`
  query GetCurrentUserWithInvites {
    currentUser {
      ...CurrentUserFields
      remainingInvites
      pendingInvites {
        id
        code
        recipientName
        expiresAt
        createdAt
      }
      acceptedInvites {
        id
        recipientName
        acceptedAt
        createdAt
      }
    }
  }
  ${CURRENT_USER_FIELDS}
`;

export const GET_CLINICAL_ROLES_QUERY = gql`
  query GetClinicalRoles {
    clinicalRoles
  }
`;

export const GET_CLINICAL_ROLE_TYPES_QUERY = gql`
  query GetClinicalRoleTypes {
    clinicalRoleTypes
  }
`;

export const GET_EMPLOYMENT_TYPES_QUERY = gql`
  query GetEmploymentTypes {
    employmentTypes
  }
`;

export const GET_SPECIALTIES_QUERY = gql`
  query GetSpecialties {
    specialties
  }
`;

export const WORK_DETAILS_PAGE_QUERY = gql`
  query WorkDetailsPageQuery {
    currentUser {
      ...CurrentUserFields
    }
    clinicalRoles
    clinicalRoleTypes
    employmentTypes
    specialties
  }
  ${CURRENT_USER_FIELDS}
`;

export const UPDATE_USER_NAME_MUTATION = gql`
  mutation UpdateUserName($firstName: String!, $lastName: String!) {
    updateUserName(firstName: $firstName, lastName: $lastName) {
      user {
        id
        firstName
        lastName
      }
      errors
    }
  }
`;

export const UPDATE_WORK_PROFILE_MUTATION = gql`
  mutation UpdateWorkProfile(
    $specialty: String
    $clinicalRole: String
    $clinicalRoleType: String
    $employmentType: String
    $startedNursingDate: ISO8601Date
  ) {
    updateWorkProfile(
      specialty: $specialty
      clinicalRole: $clinicalRole
      clinicalRoleType: $clinicalRoleType
      employmentType: $employmentType
      startedNursingDate: $startedNursingDate
    ) {
      user {
        id
        specialty
        clinicalRole
        clinicalRoleType
        employmentType
        startedNursingDate
      }
      errors
    }
  }
`;

export const UPDATE_LOCATION_MUTATION = gql`
  mutation UpdateLocation(
    $currentCity: String
    $currentState: String
    $currentFacility: String
  ) {
    updateLocation(
      currentCity: $currentCity
      currentState: $currentState
      currentFacility: $currentFacility
    ) {
      user {
        id
        currentCity
        currentState
        currentFacility
      }
      errors
    }
  }
`;
export const CREATE_INVITE_MUTATION = gql`
  mutation CreateInvite($recipientName: String!) {
    createInvite(recipientName: $recipientName) {
      invite {
        id
        code
        recipientName
      }
      errors
    }
  }
`;

export const CLAIM_INVITE_MUTATION = gql`
  mutation ClaimInvite($code: String!, $email: String!) {
    claimInvite(code: $code, email: $email) {
      success
      errors
    }
  }
`;

export const VERIFY_LICENSE_MUTATION = gql`
  mutation VerifyLicense(
    $licenseType: String
    $licenseNumber: String
    $licenseState: String
    $ncsbnId: String
  ) {
    verifyLicense(
      licenseType: $licenseType
      licenseNumber: $licenseNumber
      licenseState: $licenseState
      ncsbnId: $ncsbnId
    ) {
      success
      message
    }
  }
`;
