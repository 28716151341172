import React from "react";
import { Button } from 'antd';
import "./Post.css";

import { ReactComponent as Bird } from "../../assets/bird.svg";

export const EmptyPostState = ({ text, onClick }) => {
  const onClickHandle = () => {
    onClick && onClick()
  }

  return (
    <div className="ThreadBirdContainerBox">
      <Bird />
      <span className="WaitingCommunityText uppercase">
        It’s a little quiet here... Get the conversation going!
      </span>
      <Button
        size='small'
        type='default'
        iconPosition='end'
        onClick={onClickHandle}
        style={{ backgroundColor: '#D9F564' }}
        className={`EmptyPostButton uppercase`}>
        {text}
      </Button>

    </div>
  );
};