import React, { useEffect, useState } from "react";
import { FlatFeed, useStreamContext } from "react-activity-feed";

import { AuthenticatedLayout } from "../../layoutsHOC/AuthenticatedLayout";
import { ProfileHeader } from "../../components/Profile/ProfileHeader";

import { CREATE_POST_PATH, FeedGroupAndGroupIdFromFeedId } from "../../routes";
import { useNavigate, useParams } from "react-router-dom";
import { useStreamFollowers } from "../../services/StreamFollowers";
import Post from "../../components/Post";
import ProgressLoader from "../../components/ProgressLoader";
import InfiniteScrollPaginator from "../../components/InfiniteScrollPaginator";
import { EmptyPostState } from "../../components/Post/EmptyPost";

function ProfilePageInner() {
  let { feedId, externalUserId } = useParams();

  const { client } = useStreamContext();
  const [externalUser, setExternalUser] = useState(null);

  useEffect(() => {
    if (!externalUserId || !client || !!externalUser) return;

    client
      .user(externalUserId)
      .get()
      .then((user) => {
        setExternalUser(user);
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
      });
  }, [client, externalUserId, externalUser]);

  const navigate = useNavigate();

  const { followUser, unfollowUser, isFollowingUserId } = useStreamFollowers();

  const { feedGroup, feedGroupId } = FeedGroupAndGroupIdFromFeedId({
    feedId,
    user: externalUser,
    externalUserId,
  });

  // Needs to be reviewed.
  const isFollowing = isFollowingUserId(externalUser?.id);

  return (
    <div style={{ maxWidth: "720px", width: "100%" }}>
      <ProfileHeader
        userId={externalUser?.full?.id}
        name={externalUser?.full?.data?.name}
        specialty={externalUser?.full?.data?.specialty}
        specialtyIcon={externalUser?.full?.data?.specialty_icon}
        yearsOfExperience={externalUser?.full?.data?.years_of_experience}
        isCurrentUser={false}
        isFollowing={isFollowing}
        followUser={followUser}
        unfollowUser={unfollowUser}
      />

      <FlatFeed
        notify
        feedGroup={feedGroup}
        userId={feedGroupId}
        options={{
          limit: 5,
          withOwnReactions: true,
          withReactionCounts: true,
        }}
        Placeholder={
          <EmptyPostState
            text="POST SOMETHING"
            onClick={() => navigate(CREATE_POST_PATH)}
          />
        }
        LoadingIndicator={ProgressLoader}
        Paginator={InfiniteScrollPaginator}
        Activity={({ activity }) => (
          <Post
            currentStreamUser={externalUser}
            key={activity?.id}
            activity={activity}
            isFollowingUserId={isFollowingUserId}
            followUser={followUser}
            unfollowUser={unfollowUser}
          />
        )}
      />
    </div>
  );
}

export default function ExternalProfilePage(props) {
  return (
    <AuthenticatedLayout {...props} hideHeader={true}>
      <ProfilePageInner {...props} />
    </AuthenticatedLayout>
  );
}
