import React from "react";
import "./SignupFooter.css";
import { RubieButton } from "../../../components/RubieButton";
import { ReactComponent as Linkedin } from "../../../assets/linkedinLogo.svg";
import { Link } from "@nextui-org/react";
import { PRIVACY_URL, TERMS_URL } from "../../../helpers/config";
import { LOGIN_PATH } from "../../../routes";

export const SignUpFooterText = () => {
  return (
    <div className="SignUpFooterText">
      By continuing you agree to Rubie’s{" "}
      <Link href={PRIVACY_URL} target="_blank">
        Privacy Policy
      </Link>{" "}
      and{" "}
      <Link href={TERMS_URL} target="_blank">
        Terms & Conditions.
      </Link>
    </div>
  );
};

export function SignUpFooter({ notFound, resetForm, loading }) {
  return (
    <div className="SignUpFooterContainer">
      <SignUpFooterText />
      {!notFound ? (
        <>
          <RubieButton loading={loading} type="submit">
            Continue
          </RubieButton>
          <RubieButton loading={loading} color="primary">
            Continue with <Linkedin className="SignUpFooterLinkedin" />
          </RubieButton>
        </>
      ) : (
        <>
          <RubieButton
            key="tryAgain"
            type="button"
            color="primary"
            onPress={resetForm}
          >
            Try again
          </RubieButton>
        </>
      )}
      <div className="SignUpFooterLogIn">
        <span>
          Already have an account? <Link href={LOGIN_PATH}>Log In</Link>
        </span>
      </div>
    </div>
  );
}
