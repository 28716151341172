import React, { useState } from "react";
import { orderBy } from "lodash";
import { AuthenticatedLayout } from "../layoutsHOC/AuthenticatedLayout";
import { Trending } from "../components/Trending/Trending";
import { DiscoverHeader } from "../components/Discover/DiscoverHeader";
import { StartBrowsing } from "../components/StartBrowsing/StartBrowsing";
import { TRENDINGS } from "../helpers/fakeData";
import { TitleHeader } from "../components/TitleHeader/TitleHeader";

export default function SearchPage() {
  const [focusedSearch, setFocusedSearch] = useState(false);
  const [viewTrendings, setViewTrendings] = useState(true);
  const [trendingCards, setTrendingCards] = useState(TRENDINGS);

  const orderTrendings = (category) => {
    let listCategorySelected = orderBy(trendingCards, (x) => x.id).filter(
      (x) => x.category === category
    );
    let listCategory = orderBy(trendingCards, (x) => x.id).filter(
      (x) => x.category !== category
    );
    let newList = [...listCategory, ...listCategorySelected];
    setTrendingCards(newList);
  };

  const submitTrending = (id) => { };

  return (
    <AuthenticatedLayout
      backgroundColor={focusedSearch ? "#FDE0FE" : undefined}
      ignoreHeader
    >
      {!focusedSearch && (
        <StartBrowsing orderTrendings={orderTrendings} />
      )}
    </AuthenticatedLayout>
  );
}
