import React from "react";
import { Button } from "@nextui-org/react";

import { useNavigate } from "react-router-dom";
import { isNil } from "lodash";

import { ReactComponent as Comments } from "../../assets/comments.svg";

import "./PostFooter.css";
import { THREAD_PATH, FEED_ID_USER, GetThreadUrlPath } from "../../routes";

export default function CommentsButton({
  activity,
  hideCommentButton,
  showComments,
}) {
  const navigate = useNavigate();

  const { reaction_counts } = activity;

  const postId = activity.id;
  const commentCount = reaction_counts?.comment || 0;
  const topic = activity?.topic?.toLowerCase() || FEED_ID_USER;

  const goToComments = () => {
    const path = GetThreadUrlPath({ feedId: topic, activityId: postId });

    navigate(path);
  };

  const handleCommentButtonClick = () => {
    if (showComments) {
      // Comments are being displayed
      // TODO: set focus to the comment input
    } else {
      // Comments are not being displayed, so navigate to the thread
      goToComments();
    }
  };

  if (!isNil(hideCommentButton)) {
    return null;
  }

  if (hideCommentButton) {
    return null;
  }

  return (
    <Button
      onClick={handleCommentButtonClick}
      radius="none"
      color="transparent"
      className="border-r-1 border-black"
      startContent={<Comments />}
    >
      {commentCount}
    </Button>
  );
}
