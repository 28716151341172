import React from "react";
import { Header } from "./components/Header";
import "./Settings.css";
import { Avatar } from "../../components/Avatar/Avatar";
import { ReactComponent as Card } from "../../assets/card.svg";
import { ReactComponent as Stethoscope } from "../../assets/Stethoscope.svg";
import { ReactComponent as Checkpoint } from "../../assets/Checkpoint.svg";
import { ReactComponent as Runner } from "../../assets/Runner.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  SETTINGS_LOCATIONS_PATH,
  SETTINGS_PERSONAL_INFORMATION_PATH,
  SETTINGS_WORK_DETAILS_PATH,
  USER_PROFILE_PATH,
} from "../../routes";
import { LogoutLocalStorage } from "../../services/RubieAuth";
import { useStytch } from "@stytch/react";
import { useStreamContext } from "react-activity-feed";
import { AuthenticatedLayout } from "../../layoutsHOC/AuthenticatedLayout";
import { useCommentStore } from "../../stores/commentStore";

function SettingsPageInner() {
  const navigate = useNavigate();
  const stytch = useStytch();
  // Get the Stytch User object if available
  const { user } = useStreamContext();
  const { clearComments } = useCommentStore();

  const handleLogout = async () => {
    clearComments();
    await LogoutLocalStorage();
    await stytch.session.revoke();
    window.location.href = "/";
  };

  return (
    <div className="SettingsContainer">
      <Header title="Settings" showTitle backButtonPath={USER_PROFILE_PATH} />
      <div className="SettingsContent">
        <Avatar
          backgroundColor="#FFC3FE"
          name={user?.full?.data?.name}
          size="large"
        />
        <div className="SettingsUserName">{user?.full?.data?.name}</div>
        <div className="SettingsUserId">{user?.full?.data?.licenseNumber}</div>
      </div>
      <div className="SettingsItems">
        <div
          onClick={() => navigate(SETTINGS_PERSONAL_INFORMATION_PATH)}
          className="SettingsItem"
        >
          <Card width={30} />
          <div className="SettingsItemDescription">Personal Information</div>
        </div>
        <div
          onClick={() => navigate(SETTINGS_WORK_DETAILS_PATH)}
          className="SettingsItem"
        >
          <Stethoscope />
          <div className="SettingsItemDescription">Work Details</div>
        </div>
        {/* <div
          onClick={() => navigate(SETTINGS_CONNECTED_ACCOUNTS_PATH)}
          className="SettingsItem"
        >
          <Plug />
          <div className="SettingsItemDescription">Connected Accounts</div>
        </div> */}
        <div
          onClick={() => navigate(SETTINGS_LOCATIONS_PATH)}
          className="SettingsItem"
        >
          <Checkpoint />
          <div className="SettingsItemDescription">Location</div>
        </div>
        {/* <di
          onClick={() => navigate(SETTINGS_NOTIFICATIONS_PATH)}
          className="SettingsItem"
        >
          <Bell />
          <div className="SettingsItemDescription">Notifications</div>
        </div> */}
        {/* <div onClick={() => navigate(SETTINGS_CHANGE_PASSWORD_PATH)} className="SettingsItem">
          <Padlock width={30} />
          <div className="SettingsItemDescription">Change Password</div>
        </div> */}

        <div
          className="SettingsItem"
          onClick={() =>
            window.open(
              "https://form.asana.com/?k=wbJGpcc_iVEFDEji8IcfRA&d=1206341418516187",
              "_blank"
            )
          }
        >
          🐛
          <div className="SettingsItemDescription">Report Feedback / Bug</div>
        </div>

        <div className="SettingsItem" onClick={handleLogout}>
          <Runner />
          <div className="SettingsItemDescription">Log Out</div>
        </div>

        <div className="SettingsItem SettingsItemLast" onClick={handleLogout}>
          <div className="AccountVerifyFooterText">
            Have questions or need help? Email us at{" "}
            <Link href="mailto:support@rubiehealth.com">
              support@rubiehealth.com
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function SettingsPage(props) {
  return (
    <AuthenticatedLayout {...props} hideHeader={true}>
      <SettingsPageInner {...props} />
    </AuthenticatedLayout>
  );
}
