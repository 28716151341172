import React from "react";

import { AuthenticatedLayout } from "../../layoutsHOC/AuthenticatedLayout";

import { ReactComponent as EmojiDoctor } from "../../assets/EmojiDoctor.svg";

import { ReactComponent as EmojiHeart } from "../../assets/EmojiHeart.svg";
import { ReactComponent as EmojiConfetti } from "../../assets/confetti.svg";
import { ReactComponent as EmojiStars } from "../../assets/EmojiStars.svg";

import "./Guidelines.css";

import { Header } from "../Settings/components/Header";

function GuidelinesPatientPrivacyInner() {
  return (
    <div style={{ maxWidth: "720px", width: "100%", backgroundColor: "white" }}>
      <Header title={"Community Guidelines"} showTitle={false} />
      <div className="flex flex-col pt-4 pb-4 gap-4 pl-4 pr-4">
        <EmojiDoctor height={40} width={40} />
        <span className="GuidelinesTitle">
          Respect Your Fellow Community Members
        </span>
        <span className="GuidelinesDescription mt-2">
          In our friendly community, we treasure respect and professionalism.
          So, we might need to remove any content that seems to intentionally
          single out individual members or groups, with the clear aim of
          belittling or embarrassing them.
        </span>
        <span className="GuidelinesDescription">
          This could include, but is not limited to:
        </span>
        <GuidelineCommunityItem
          number="1."
          text={
            <span className="GuidelinesDescription">
              Comments that seem <strong>discriminatory</strong> based on race
              or ethnic origin, religion, disability, gender, age, nationality,
              veteran status, sexual orientation, gender identity, or medical
              specialty.
            </span>
          }
        />
        <GuidelineCommunityItem
          number="2."
          text={
            <span className="GuidelinesDescription">
              Remarks that seem <strong>offensive</strong> or unprofessional and
              don't help to create a positive community vibe.
            </span>
          }
        />
        <GuidelineCommunityItem
          number="3."
          text={
            <span className="GuidelinesDescription">
              <strong>Bad language</strong> - we're firm believers in keeping
              our language professional and respectful at all times.
            </span>
          }
        />
        <GuidelineCommunityItem
          number="4."
          text={
            <span className="GuidelinesDescription">
              Personal <strong>attacks, insults, or threats -</strong> we're
              totally against any form of harassment or bullying.
            </span>
          }
        />
        <GuidelineCommunityItem
          number="5."
          text={
            <span className="GuidelinesDescription">
              Sharing <strong>false or misleading info -</strong> we think it's
              super important to keep our environment filled with accurate and
              trustworthy information.
            </span>
          }
        />
        <GuidelineCommunityItem
          number="6."
          text={
            <span className="GuidelinesDescription">
              <strong>Bullying or harassing</strong> other lovely members of our
              community.
            </span>
          }
        />
        <div className="flex flex-row gap-4">
          <EmojiHeart />
          <EmojiConfetti />
          <EmojiStars />
        </div>
        <span className="GuidelinesDescription">
          We're cheering on all members to help create a positive and respectful
          atmosphere in our community. Go team!
        </span>
      </div>
    </div>
  );
}

const GuidelineCommunityItem = ({ number, text }) => {
  return (
    <div className="GuidelineCommunityItem">
      <span className="GuidelinesDescription">{number}</span>
      {text}
    </div>
  );
};

export default function GuidelinesCommunityMembers(props) {
  return (
    <AuthenticatedLayout
      {...props}
      backgroundColor={"#FBFDFE"}
      hideHeader={true}
      hideFooter={true}
    >
      <GuidelinesPatientPrivacyInner {...props} />
    </AuthenticatedLayout>
  );
}
