import React from "react";
import { useNavigate } from "react-router-dom";

import { AuthenticatedLayout } from "../layoutsHOC/AuthenticatedLayout";
import { CLAIM_INVITE_PATH, LOGIN_PATH, DISCOVER_PATH } from "../routes";
import WithQuery from "../components/WithQuery";
import { CURRENT_USER_AUTH_QUERY } from "../graphql/user";
import { RubieButton } from "../components/RubieButton";
import { useStytch } from "@stytch/react";
import { LogoutLocalStorage } from "../services/RubieAuth";

import "../layoutsHOC/PublicLayout.css";

const WaitlistPageInner = ({ data, loading }) => {
  const navigate = useNavigate();
  const stytch = useStytch();

  React.useEffect(() => {
    if (loading) return;

    if (data?.currentUser && !data.currentUser?.accountVerified) {
      navigate(DISCOVER_PATH);
    }

    if (!data?.currentUser) {
      navigate(LOGIN_PATH);
    }
  }, [data, navigate, loading]);

  const handleHaveInvite = async () => {
    await LogoutLocalStorage();
    await stytch.session.revoke();
    navigate(CLAIM_INVITE_PATH);
  };

  return (
    <AuthenticatedLayout hideHeader hideFooter>
      <div className="WelcomeContainer">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <div className="WelcomeTitle" style={{ fontSize: "28px" }}>
            Rubie is invite-only
            <br />
            (for now)
          </div>
          <div className="WelcomeDescriptionText" style={{ fontSize: "14px" }}>
            If you have an invite, click the button below. Otherwise, get on the
            waitlist!
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
            gap: 20,
          }}
        >
          <div className="space-y-4 flex flex-col items-center justify-center">
            <RubieButton
              onPress={() =>
                (window.location.href = "https://www.rubiehealth.com")
              }
              style={{ width: 330 }}
              size="sm"
            >
              Get on the waitlist
            </RubieButton>
            <RubieButton
              onPress={handleHaveInvite}
              color="primary"
              style={{ width: 330 }}
              size="sm"
            >
              Claim an Invite
            </RubieButton>
          </div>
        </div>
      </div>
    </AuthenticatedLayout>
  );
};

export default function WaitlistPage() {
  return WithQuery(WaitlistPageInner, CURRENT_USER_AUTH_QUERY, {}, {})();
}
