import React from 'react';
import instruction from '../../../assets/Instruction.svg';
import { ReactComponent as Ghost } from '../../../assets/Ghost.svg';

export function SignUpLicenseNotFound() {
  return (
    <div className='SignUpStep'>
      <img width={40} className='SignUpStepImg' src={instruction} alt='Instruction' />
      <div className='SignUpStepTitle'>Oops! License Not Found</div>
      <div className='SignUpStepDescription'>Please double-check the information and try again. If you're still having trouble, contact support@gorubie.com for assistance. We're here to help!</div>
      <div className='SignUpCard'>
        <div style={{ background: '#FAF4DA' }} className='SignUpCardHeader'>
          <div style={{ width: 222 }} className='SignUpCardHeaderText'>
          </div>
        </div>
        <div style={{ background: '#FAF4DA', padding: 12 }} className='SignUpCardBody'>
          <div className='SignUpCardBodyNotFound'>
            <Ghost />
            <div className='SignUpCardBodyNotFoundText'>
              WE COULDN’T FIND YOU <br />
              PLEASE CHECK YOUR INFO
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}