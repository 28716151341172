import React, { useState } from "react";
import card from "../../../assets/card.svg";
import illustrationBg from "../../../assets/IllustrationBg.png";

import { Drawer, Form, Input, Radio, Select, Space } from "antd";
import { Button } from "@nextui-org/button";
import { ReactComponent as Close } from "../../../assets/Close.svg";
import { ReactComponent as IdentificationBadge } from "../../../assets/IdentificationBadge.svg";
import { ReactComponent as IdentificationBadge2 } from "../../../assets/IdentificationBadge2.svg";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useSwipeable } from "react-swipeable";
import { AMERICAN_STATES_AND_TERRITORIES } from "../../../helpers/states";

const LICENSE_TYPE_OPTIONS = [
  {
    value: "RN",
    label: "RN",
  },
  {
    value: "PN",
    label: "PN",
  },
  {
    value: "CNP",
    label: "CNP",
  },
  {
    value: "CNS",
    label: "CNS",
  },
  {
    value: "CNM",
    label: "CNM",
  },
  {
    value: "CRNA",
    label: "CRNA",
  },
];

export function SignUpStep3({
  form,
  validateWith,
  setValidateWith,
  licenseType,
  setLicenseType,
}) {
  const [openDrawer, setOpenDrawer] = useState(false);

  const changeValidateOption = (value) => {
    setValidateWith(value);
    reset();
  };

  const reset = () => {
    form.setFieldValue("licenseType", undefined);
    form.setFieldValue("licenseNumber", undefined);
    form.setFieldValue("licenseState", undefined);
    form.setFieldValue("ncsbnId", undefined);
    setLicenseType(undefined);
  };

  const handlers = useSwipeable({
    onSwiped: () => setOpenDrawer(false),
  });

  return (
    <div className="SignUpStep">
      <Drawer
        style={{ borderRadius: "50px 50px 0 0 " }}
        styles={{ body: { padding: 0 }, wrapper: { boxShadow: "initial" } }}
        height={500}
        onClose={() => setOpenDrawer(false)}
        closeIcon={false}
        open={openDrawer}
        placement="bottom"
      >
        <div className="SignUpStepBottom" {...handlers}>
          <div className="SignUpStepBottomGrabberBar" />
          <img
            width={246}
            height={316}
            src={illustrationBg}
            alt="Illustration"
          />
          <div className="SignUpStep">
            <div className="SignUpStepTitle">License verification</div>
            <div style={{ paddingBottom: 0 }} className="SignUpStepDescription">
              Let's make it official! Verify your nursing license to confirm
              you're a Real Nurse. Don't worry, your details are safe with us!
            </div>
          </div>
        </div>
      </Drawer>
      <img width={40} className="SignUpStepImg" src={card} alt="Card" />
      <div className="SignUpStepTitle">License verification</div>
      <div className="SignUpStepDescription">
        Let's make it official! Verify your nursing license to confirm you're a
        Real Nurse. Don't worry, your details are safe with us!
        <div className="SignUpStepQuestion" onClick={() => setOpenDrawer(true)}>
          <QuestionCircleOutlined /> Why do we ask for this?
        </div>
      </div>

      <Form.Item
        className="SignUpStepRadio"
        name="validateWith"
        label="Validate with"
        rules={[{ required: true }]}
      >
        <Space style={{ width: "100%" }} direction="vertical">
          <Radio
            onChange={() => changeValidateOption(1)}
            checked={validateWith === 1}
            value={1}
          >
            <div className="SignUpStepRadioItem">
              <IdentificationBadge /> License Number
            </div>
          </Radio>
          <Radio
            onChange={() => changeValidateOption(2)}
            checked={validateWith === 2}
            value={2}
          >
            <div className="SignUpStepRadioItem">
              <IdentificationBadge2 /> NCSBN ID
            </div>
          </Radio>
        </Space>
      </Form.Item>
      {validateWith === 1 && (
        <>
          <Form.Item
            name="licenseType"
            label="License Type"
            rules={[{ required: true }]}
          >
            <Select
              value={licenseType}
              onChange={setLicenseType}
              options={LICENSE_TYPE_OPTIONS}
              size="large"
              placeholder="Select type"
            />
          </Form.Item>

          <Form.Item
            name="licenseNumber"
            label="License Number"
            rules={[{ required: true }]}
          >
            <Input placeholder="7654321" size="large" />
          </Form.Item>
          <Form.Item
            name="licenseState"
            label="State"
            rules={[{ required: true }]}
          >
            <Select
              options={AMERICAN_STATES_AND_TERRITORIES.map((state) => {
                return {
                  value: state.value,
                  label: state.label,
                };
              })}
              size="large"
              placeholder="Select state"
            />
          </Form.Item>
        </>
      )}
      {validateWith === 2 && (
        <>
          <Form.Item
            name="ncsbnId"
            label="NCSBN ID"
            rules={[{ required: true }]}
          >
            <Input placeholder="7654321" size="large" />
          </Form.Item>
          <Form.Item name="licenseType" label="License Type">
            <Select
              value={licenseType}
              onChange={setLicenseType}
              options={LICENSE_TYPE_OPTIONS}
              size="large"
              placeholder="Select type"
            />
          </Form.Item>
          <Form.Item name="licenseState" label="State">
            <Select
              options={AMERICAN_STATES_AND_TERRITORIES.map((state) => ({
                value: state.value,
                label: state.label,
              }))}
              size="large"
              placeholder="Select state"
            />
          </Form.Item>
        </>
      )}
      <Button
        onClick={() => {
          changeValidateOption(undefined);
        }}
        className="SignUpStepReset"
        variant="light"
      >
        RESET ALL FIELDS <Close />
      </Button>
    </div>
  );
}
