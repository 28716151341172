import React from "react";
import "./Settings.css";
import { Header } from "./components/Header";
import { Form, Input } from "antd";
import { RubieButton } from "../../components/RubieButton/RubieButton";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../hooks/useAlert";
import { RubieUserService } from "../../services/RubieUser";
import WithQuery from "../../components/WithQuery";
import { CURRENT_USER_QUERY } from "../../graphql/user";
import { goBack } from "../../helpers/navigation";

function SettingsPersonalInformationPageInner({ data, loading, error }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const currentUser = data?.currentUser;

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (currentUser) {
      form.setFieldsValue({
        firstName: currentUser?.firstName || "",
        lastName: currentUser?.lastName || "",
        email: currentUser?.email || "",
      });
    }
  }, [currentUser, form]);

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const { firstName, lastName } = values;
      const result = await RubieUserService.updateUserName({
        firstName,
        lastName,
      });

      if (result.errors?.length > 0) {
        showAlert(
          "error",
          "Failed to update personal information. Please try again."
        );
      } else {
        showAlert("success", "Your personal information was updated!");
        goBack(navigate);
      }
    } catch (error) {
      console.error("Error updating personal information:", error);
      showAlert(
        "error",
        "An error occurred while updating personal information. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error loading user data</div>;

  const isDisabled = loading || isSubmitting;

  return (
    <div className="SettingsContainer">
      <Header title="Personal Information" showTitle />
      <div style={{ border: "none" }} className="SettingsContent">
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={handleSubmit}
          style={{ maxWidth: 600, width: "100%" }}
          validateMessages={{
            required: "Required field!",
            types: {
              email: "This is not a valid email!",
            },
          }}
          disabled={isDisabled}
        >
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true }]}
          >
            <Input style={{ width: "100%" }} placeholder="Jane" size="large" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true }]}
          >
            <Input placeholder="Collins" size="large" />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ type: "email" }]}>
            <Input placeholder="jane.collins@gmail.com" size="large" disabled />
          </Form.Item>
          <div className="SettingsButtons">
            <RubieButton
              variant="ghost"
              onPress={() => navigate("/settings")}
              disabled={isDisabled}
            >
              Cancel
            </RubieButton>
            <RubieButton
              type="submit"
              loading={isSubmitting}
              disabled={isDisabled}
            >
              Continue
            </RubieButton>
          </div>
        </Form>
      </div>
    </div>
  );
}

export function SettingsPersonalInformationPage() {
  return WithQuery(
    SettingsPersonalInformationPageInner,
    CURRENT_USER_QUERY,
    {},
    { refetchQueries: [{ query: CURRENT_USER_QUERY }] }
  )();
}
