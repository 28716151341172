import React from "react";
import { useStytchUser, StytchLogin } from "@stytch/react";

import { Products } from "@stytch/vanilla-js";
import { GetActiveAppUrl } from "../../helpers/config";

/*
Login configures and renders the StytchLogin component which is a prebuilt UI component for auth powered by Stytch

This component accepts style, config, and callbacks props. To learn more about possible options review the documentation at
https://stytch.com/docs/sdks/javascript-sdk#ui-configs
*/

const Login = () => {
  const styles = {
    hideHeaderText: true,
    container: {
      width: "380px",
      backgroundColor: "transparent",
      borderColor: "transparent",
      borderWidth: 0,
      padding: 0,
    },
    buttons: {
      primary: {
        backgroundColor: "#263322",
        borderColor: "#263322",
      },
    },
  };
  const config = {
    products: [Products.emailMagicLinks],
    emailMagicLinksOptions: {
      loginRedirectURL: GetActiveAppUrl() + "/authenticate",
      loginExpirationMinutes: 60,
      signupRedirectURL: GetActiveAppUrl() + "/authenticate",
      signupExpirationMinutes: 60,
    },
  };

  return (
    <StytchLogin config={config} styles={styles} />
  );
};

export default Login;
