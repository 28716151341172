import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Navbar, NavbarContent, NavbarItem, Button } from "@nextui-org/react";
import { Link } from "react-router-dom";

import { Plus } from "@phosphor-icons/react";

import { ReactComponent as Home } from "../../assets/Home.svg";
import { ReactComponent as HomeSelected } from "../../assets/HomeSelected.svg";

import { ReactComponent as Activity } from "../../assets/Activity.svg";
import { ReactComponent as ActivityWithoutDot } from "../../assets/ActivityWithoutDot.svg";
import { ReactComponent as ActivitySelected } from "../../assets/ActivitySelected.svg";

import { ReactComponent as Search } from "../../assets/Search.svg";
import { ReactComponent as SearchSelected } from "../../assets/SearchSelected.svg";

import { ReactComponent as Account } from "../../assets/Account.svg";
import { ReactComponent as AccountSelected } from "../../assets/AccountSelected.svg";
import { CompleteProfileModal } from "../CompleteProfile/CompleteProfileModal";

import {
  USER_PROFILE_PATH,
  NOTIFICATIONS_PATH,
  CREATE_POST_PATH,
  SEARCH_PATH,
  DISCOVER_PATH,
  TIMELINE_PATH,
  SETTINGS_WORK_DETAILS_PATH,
} from "../../routes";

import "./NavFooter.css";
import { IsOnTheSearchPath } from "../../helpers/routes";
import { useStreamContext } from "react-activity-feed";
import { CompleteProfileDialog } from "../Dialog/CompleteProfileDialog";

export const PageFooterHeight = 58;

export function NavFooter({ currentUser }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [displayDialog, setDisplayDialog] = useState(false);

  // Get the Stytch User object if available
  const { user } = useStreamContext();
  const hasNotification = false;

  const onStartHandleClick = () => {
    navigate(SETTINGS_WORK_DETAILS_PATH);
    // setDisplayDialog(false);
    // setTimeout(() => setOpenDrawer(true), 200);
  };
  return (
    <>
      <CompleteProfileDialog
        currentUser={currentUser}
        displayDialog={displayDialog}
        setDisplayDialog={setDisplayDialog}
        onStartHandleClick={onStartHandleClick}
      />
      <Navbar
        className="MenuFooterContainer"
        style={{ justifyContent: "center", height: `${PageFooterHeight}px` }}
      >
        <NavbarContent
          className="gap-4 w-full"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NavbarItem>
            <Link to={DISCOVER_PATH}>
              {location.pathname === DISCOVER_PATH ||
              location.pathname === TIMELINE_PATH ? (
                <HomeSelected />
              ) : (
                <Home />
              )}
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link to={SEARCH_PATH}>
              {location.pathname === SEARCH_PATH ||
              IsOnTheSearchPath(location.pathname) ? (
                <SearchSelected />
              ) : (
                <Search />
              )}
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Button
              isIconOnly
              data-hover={false}
              className="MenuCreatePostLink"
              startContent={<Plus size={25} />}
              onClick={() => {
                navigate(CREATE_POST_PATH);
              }}
            />
          </NavbarItem>
          <NavbarItem>
            <Link to={NOTIFICATIONS_PATH}>
              {location.pathname === NOTIFICATIONS_PATH ? (
                <ActivitySelected />
              ) : hasNotification ? (
                <Activity />
              ) : (
                <ActivityWithoutDot />
              )}
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link to={USER_PROFILE_PATH}>
              {location.pathname === USER_PROFILE_PATH ? (
                <AccountSelected />
              ) : (
                <Account />
              )}
            </Link>
          </NavbarItem>
        </NavbarContent>
      </Navbar>
      <CompleteProfileModal
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
      />
    </>
  );
}
