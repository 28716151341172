import React from "react";

import "./PostFooter.css";
import LikeButton from "../LikeButton";
import CommentsButton from "./CommentsButton";
import RepostButton from "./RePostButton";
import ShareButton from "./ShareButton";
import PostActionsButton from "./PostActionsButton";

export function PostFooter({
  activity,
  hideCommentButton,
  showComments,
  currentStreamUser,
  actor,
}) {
  return (
    <div className="PostFooterContainer bg-white max-width-1024-border-r-0 max-width-1024-border-l-0">
      <div className="flex flex-1">
        <LikeButton activity={activity} className="max-width-1024-border-l-0" />
        <CommentsButton
          activity={activity}
          hideCommentButton={hideCommentButton}
          showComments={showComments}
        />
        <RepostButton
          activity={activity}
          currentStreamUser={currentStreamUser}
          actor={actor}
        />
        <ShareButton activity={activity} />

        <div className="flex-1"></div>
        <PostActionsButton
          activity={activity}
          currentStreamUser={currentStreamUser}
        />
      </div>
    </div>
  );
}
