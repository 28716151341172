import React from "react";
import { Drawer, Modal } from "antd";
import { useDeviceType } from "../../hooks/useDeviceType";
import { ReactComponent as Close } from "../../assets/Close.svg";

export const UnlockInviteModal = ({ setOpenDrawer, openDrawer, handlers }) => {
  const deviceType = useDeviceType();

  if (deviceType === 'Mobile') {
    return (
      <Drawer style={{ borderRadius: '50px 50px 0 0 ' }} styles={{ body: { padding: 0 }, wrapper: { boxShadow: 'initial' } }} height={'auto'}
        onClose={() => setOpenDrawer(false)} closeIcon={false} open={openDrawer} placement='bottom'>
        <div className='UnlockInviteDrawer' {...handlers}>
          <div className='UnlockInviteDrawerGrabberBar' />
          <div className='UnlockInviteDrawerFlex'>
            <div className='UnlockInviteDrawerTitle'>Unlock more invites</div>
            <Close
              onClick={() => setOpenDrawer(false)}
              style={{ fontSize: 16, cursor: "pointer" }}
            />
          </div>
          <UnlockItemsDetails />
        </div>
      </Drawer>
    );
  }

  return (
    <Modal title={<div className='UnlockInviteDrawerTitle'>Unlock more invites</div>} footer={false}
      onCancel={() => setOpenDrawer(false)} open={openDrawer}>
      <div className='UnlockInviteDrawer'>
        <UnlockItemsDetails />
      </div>
    </Modal>
  )
}

const UnlockItemsDetails = () => {
  return (
    <div className="UnlockInviteDrawerItems">
      <div className="UnlockInviteDrawerItem">
        <div className="UnlockInviteDrawerItemIcon">
          🧊
        </div>
        <div className="UnlockInviteDrawerItemContent">
          <div className="UnlockInviteDrawerItemTitle">Icebreaker</div>
          <div className="UnlockInviteDrawerItemDescription">Get started by writing your first post</div>
          <div className="UnlockInviteDrawerItemInfo">Unlock 1 invite</div>
        </div>
      </div>
      <div className="UnlockInviteDrawerItem">
        <div className="UnlockInviteDrawerItemIcon">
          💪
        </div>
        <div className="UnlockInviteDrawerItemContent">
          <div className="UnlockInviteDrawerItemTitle">Professional Power Move</div>
          <div className="UnlockInviteDrawerItemDescription">Connect your Linkedin profile.</div>
          <div className="UnlockInviteDrawerItemInfo">Unlock 1 invite</div>
        </div>
      </div>
      <div className="UnlockInviteDrawerItem">
        <div className="UnlockInviteDrawerItemIcon">
          🔗
        </div>
        <div className="UnlockInviteDrawerItemContent">
          <div className="UnlockInviteDrawerItemTitle">Sharing is Caring</div>
          <div className="UnlockInviteDrawerItemDescription">Share a post outside of Rubie.</div>
          <div className="UnlockInviteDrawerItemInfo">Unlock 1 invite</div>
        </div>
      </div>
    </div>
  )
}