import React from "react";
import { Button } from "@nextui-org/react";

import { useFeedContext } from "react-activity-feed";

import { ReactComponent as Like } from "../assets/like.svg";

export default function LikeButton({ activity, reaction, className }) {
  const feed = useFeedContext();

  if (!reaction && !activity) return null;

  const reactionKind = "like";

  const reaction_counts =
    reaction?.children_counts ?? activity?.reaction_counts;
  const likeCount = reaction_counts?.[reactionKind ?? ""] || 0;

  const ownReactions = reaction?.own_children ?? activity?.own_reactions;
  const ownLike = ownReactions?.[reactionKind ?? ""]?.length;

  const onLikeHandleClick = () => {
    if (activity) return feed.onToggleReaction(reactionKind, activity, {}, {});
    if (reaction)
      return feed.onToggleChildReaction(reactionKind, reaction, {}, {});
  };

  return (
    <Button
      className={`border-r-1 border-black ` + className || ""}
      radius="none"
      color="transparent"
      onClick={onLikeHandleClick}
      startContent={<Like fill={ownLike ? "#F6C5FA" : "transparent"} />}
    >
      {likeCount}
    </Button>
  );
}
