import { Select } from "antd";

const CompleteProfileStep4 = ({ speciality, setSpeciality }) => {
  return (<div className="CompleteProfileForm">
    <label>Current Specialty</label>
    <Select style={{ width: '100%' }} speciality={speciality} onChange={setSpeciality} showSearch placeholder="e.g. Pediatric Nurse" size='large'
      options={[{
        label: 'Aesthetics/Nurse Injector',
        value: 'Aesthetics/Nurse Injector'
      }, {
        label: 'Acute Care Float Pool',
        value: 'Acute Care Float Pool'
      }, {
        label: 'Ambulatory Care',
        value: 'Ambulatory Care'
      }, {
        label: 'Ambulatory Surgery',
        value: 'Ambulatory Surgery'
      }, {
        label: 'Burn',
        value: 'Burn'
      }, {
        label: 'Camp',
        value: 'Camp'
      }, {
        label: 'Neonatal',
        value: 'Neonatal'
      },]} />
  </div>)
}

export default CompleteProfileStep4;