import * as React from "react";

import { Routes, Route } from "react-router-dom";

// Public pages
import LoginPage from "./pages/Login";
import AuthenticatePage from "./pages/Authenticate";

// Private pages
import TimelinePage from "./pages/Timeline";
import NotificationsPage from "./pages/Notifications";
import SearchPage from "./pages/Search";
import ProfilePage from "./pages/Profile/UserProfile";
import ThreadPage from "./pages/Thread";
import CreatePostPage from "./pages/CreatePost";

// HOCs
import AuthenticatedRoute from "./layoutsHOC/AuthenticatedRoute";
import {
  SettingsChangePasswordPage,
  SettingsConnectedAccountsPage,
  SettingsLocationsPage,
  SettingsNotificationsPage,
  SettingsPersonalInformationPage,
  SettingsWorkDetailsPage,
} from "./pages/Settings";
import { SignUp } from "./pages/SignUp/Signup";
import { FEED_TOPIC_TITLES } from "./helpers/feed";
import ExternalProfilePage from "./pages/Profile/ExternalProfile";
import SettingsPage from "./pages/Settings/Settings";
import CommunityGuidelines from "./pages/Guidelines/CommunityGuidelines";
import GuidelinesPatientPrivacy from "./pages/Guidelines/GuidelinesPatientPrivacy";
import GuidelinesCommunityMembers from "./pages/Guidelines/GuidelinesCommunityMembers";
import GuidelinesIntellectualProperty from "./pages/Guidelines/GuidelinesIntellectualProperty";
import GuidelinesViolateCommunity from "./pages/Guidelines/GuidelinesViolateCommunity";
import ClaimInvite from "./pages/ClaimInvite";
import WaitlistPage from "./pages/WaitlistPage";
import AccountVerifyPage from "./pages/AccountVerify/AccountVerifyPage";

// ---------Public routes---------
export const LOGIN_PATH = "/login";
export const AUTH_PATH = "/authenticate";
export const SIGNUP_PATH = "/signup";

export const CLAIM_INVITE_PATH = "/claim-invite";

// ---------Private routes---------

// Account stages
export const WAITLIST_PATH = "/waitlist";
export const VERIFY_ACCOUNT_PATH = "/verify";

// Main pages
export const DISCOVER_PATH = "/";
export const TIMELINE_PATH = "/following";
export const FEED_ID_PATH = "/t/:feedId/";
export const THREAD_PATH = "/t/:feedId/:activityId";
export const CREATE_POST_PATH = "/create";

export const NOTIFICATIONS_PATH = "/notifications";
export const SEARCH_PATH = "/search";

// Settings
export const SETTINGS_PATH = "/settings";
export const SETTINGS_PERSONAL_INFORMATION_PATH =
  "/settings/personal-information";
export const SETTINGS_WORK_DETAILS_PATH = "/settings/work-details";
export const SETTINGS_LOCATIONS_PATH = "/settings/locations";
export const SETTINGS_CHANGE_PASSWORD_PATH = "/settings/change-password";
export const SETTINGS_NOTIFICATIONS_PATH = "/settings/notifications";
export const SETTINGS_CONNECTED_ACCOUNTS_PATH = "/settings/connected-accounts";

// Profile
export const USER_PROFILE_PATH = "/profile";
export const EXTERNAL_USER_PROFILE_PATH = "/u/:externalUserId";
export const USER_PROFILE_INVITES_PATH = "/invites";

// Guidelines
export const GUIDELINES = "/guidelines";
export const GUIDELINES_PATIENT_PRIVACY = "/guidelines/patient-privacy";
export const GUIDELINES_COMMUNITY_MEMBERS =
  "/guidelines/respect-community-members";
export const GUIDELINES_INTELLECTUAL_PROPERTY =
  "/guidelines/respect-intellectual-property";
export const GUIDELINES_VIOLATE_COMMUNITY_GUIDELINES =
  "/guidelines/what-happens-violate-community-guidelines";

// Stream feed ids
export const FEED_ID_USER = "user";

export const FEED_ID_TIMELINE = "timeline";

export const FEED_ID_DISCOVER = "discover";

export const FEED_ID_TOPIC = "topic";

// export const FEED_ID_NOTIFICATIONS = "notifications";

export const GetFeedIdUrlPath = ({ feedId }) =>
  FEED_ID_PATH.replace(":feedId", feedId);

export const GetThreadUrlPath = ({ feedId, activityId }) =>
  THREAD_PATH.replace(":feedId", feedId).replace(":activityId", activityId);

export const GetFullUrlWithPath = (path) => {
  return window.location.origin + path;
};

export const GetExternalUserProfilePath = ({ userId }) =>
  EXTERNAL_USER_PROFILE_PATH.replace(":externalUserId", userId);

export const FEED_TOPIC_VALUE_LABELS = Object.entries(FEED_TOPIC_TITLES).map(
  ([value, label]) => ({ value, label })
);

export const IsTopicTypeFeedId = (feedId) =>
  Object.keys(FEED_TOPIC_TITLES).includes(feedId);

export const CompareUrlPath = (path1, path2) => {
  return (
    path1.split("/").slice(0, 2).join("/") ===
    path2.split("/").slice(0, 2).join("/")
  );
};

export const FeedGroupAndGroupIdFromFeedId = ({
  feedId,
  user,
  externalUserId,
}) => {
  // strip anything after the 2nd slash
  let urlPath = window.location.pathname;
  urlPath = urlPath.split("/").slice(0, 2).join("/");

  if (!feedId || feedId === "") {
    if (CompareUrlPath(urlPath, TIMELINE_PATH)) feedId = FEED_ID_TIMELINE;
    if (CompareUrlPath(urlPath, DISCOVER_PATH)) feedId = FEED_ID_DISCOVER;
  }

  let feedGroup,
    feedGroupId = null;

  if (IsTopicTypeFeedId(feedId)) {
    feedGroup = FEED_ID_TOPIC;
    feedGroupId = feedId;
  } else if ([FEED_ID_TIMELINE, FEED_ID_DISCOVER].includes(feedId)) {
    feedGroup = feedId;
    feedGroupId = user?.id;
  } else if (CompareUrlPath(urlPath, USER_PROFILE_PATH)) {
    feedGroup = FEED_ID_USER;
    feedGroupId = user?.id;
  } else if (CompareUrlPath(urlPath, EXTERNAL_USER_PROFILE_PATH)) {
    feedGroup = FEED_ID_USER;
    feedGroupId = externalUserId;
  } else {
    feedGroup = feedId;
  }

  return { feedGroup, feedGroupId };
};

export function RubieRoutes() {
  return (
    <Routes>
      {/* Public routes */}
      <Route path={LOGIN_PATH} element={<LoginPage />} />

      <Route path={AUTH_PATH} element={<AuthenticatePage />} />
      <Route path={SIGNUP_PATH} element={<SignUp />} />
      <Route path={CLAIM_INVITE_PATH} element={<ClaimInvite />} />

      <Route path={VERIFY_ACCOUNT_PATH} element={<AccountVerifyPage />} />
      <Route path={WAITLIST_PATH} element={<WaitlistPage />} />

      {/* Private routes */}
      <Route path="/" element={<AuthenticatedRoute />}>
        <Route path={TIMELINE_PATH} element={<TimelinePage />} />
        <Route path={DISCOVER_PATH} element={<TimelinePage />} />
        <Route path={FEED_ID_PATH} element={<TimelinePage />} />
        <Route path={THREAD_PATH} element={<ThreadPage />} />

        <Route path={CREATE_POST_PATH} element={<CreatePostPage />} />

        <Route path={USER_PROFILE_PATH} element={<ProfilePage />} />
        <Route
          path={EXTERNAL_USER_PROFILE_PATH}
          element={<ExternalProfilePage />}
        />

        <Route path={NOTIFICATIONS_PATH} element={<NotificationsPage />} />

        <Route path={SEARCH_PATH} element={<SearchPage />} />
        <Route path={SETTINGS_PATH} element={<SettingsPage />} />
        <Route
          path={SETTINGS_PERSONAL_INFORMATION_PATH}
          element={<SettingsPersonalInformationPage />}
        />
        <Route
          path={SETTINGS_WORK_DETAILS_PATH}
          element={<SettingsWorkDetailsPage />}
        />
        <Route
          path={SETTINGS_LOCATIONS_PATH}
          element={<SettingsLocationsPage />}
        />
        <Route
          path={SETTINGS_CHANGE_PASSWORD_PATH}
          element={<SettingsChangePasswordPage />}
        />
        <Route
          path={SETTINGS_NOTIFICATIONS_PATH}
          element={<SettingsNotificationsPage />}
        />
        <Route
          path={SETTINGS_CONNECTED_ACCOUNTS_PATH}
          element={<SettingsConnectedAccountsPage />}
        />
        <Route path={GUIDELINES} element={<CommunityGuidelines />} />
        <Route
          path={GUIDELINES_PATIENT_PRIVACY}
          element={<GuidelinesPatientPrivacy />}
        />
        <Route
          path={GUIDELINES_COMMUNITY_MEMBERS}
          element={<GuidelinesCommunityMembers />}
        />
        <Route
          path={GUIDELINES_INTELLECTUAL_PROPERTY}
          element={<GuidelinesIntellectualProperty />}
        />
        <Route
          path={GUIDELINES_VIOLATE_COMMUNITY_GUIDELINES}
          element={<GuidelinesViolateCommunity />}
        />
      </Route>
    </Routes>
  );
}
