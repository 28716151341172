import React, { useState } from "react";
import confetti from "../../../assets/confetti.svg";
import { Avatar } from "../../../components/Avatar";
import { ReloadOutlined } from "@ant-design/icons";
import { AuthenticateRubieUser } from "../../../services/RubieAuth";

export function AccountVerifyStep4({
  onSubmitSuccess,
  setSubmitForm,
  currentUser,
  loading,
  stepBack,
}) {
  const handleSubmit = () => {
    // wait 1 second before calling onSubmitSuccess
    setTimeout(async () => {
      // Make sure we grab the stream token
      await AuthenticateRubieUser();

      onSubmitSuccess();
    }, 1000);
  };

  React.useEffect(() => {
    setSubmitForm(() => handleSubmit);
  }, [setSubmitForm]);

  return (
    <div className="AccountVerifyStep">
      <img
        width={40}
        className="AccountVerifyStepImg"
        src={confetti}
        alt="Confetti"
      />
      <div className="AccountVerifyStepTitle">We found you!</div>
      <div className="AccountVerifyStepDescription">
        Almost done! Please review your information before continuing.
      </div>
      <div className="AccountVerifyCard">
        <div className="AccountVerifyCardHeader">
          <div className="AccountVerifyCardHeaderText">
            <Avatar
              size="medium"
              name="Registered Nurse"
              backgroundColor="#FFC3FD"
            />{" "}
            Registered Nurse
          </div>
        </div>
        <div className="AccountVerifyCardBody">
          <div className="AccountVerifyCardFullName">
            {currentUser?.firstName} {currentUser?.lastName}
          </div>
          <div className="AccountVerifyCardInfo">
            {currentUser?.licenseNumber &&
              currentUser?.licenseNumber !== "" && (
                <>
                  <div className="AccountVerifyCardInfoItem">
                    <h3>License Number</h3>
                    <h4>{currentUser?.licenseNumber}</h4>
                  </div>
                  <div className="AccountVerifyCardInfoItem">
                    <h3>License Type</h3>
                    <h4>{currentUser?.licenseType}</h4>
                  </div>
                  <div className="AccountVerifyCardInfoItem AccountVerifyCardInfoSingleItem">
                    <h3>State</h3>
                    <h4>{currentUser?.licenseState}</h4>
                  </div>
                </>
              )}
            {(!currentUser?.licenseNumber ||
              currentUser?.licenseNumber === "") &&
              currentUser?.ncsbnId &&
              currentUser?.ncsbnId !== "" && (
                <>
                  <div
                    className={`AccountVerifyCardInfoItem ${
                      !currentUser?.licenseType && !currentUser?.licenseState
                        ? "AccountVerifyCardInfoSingleItem"
                        : ""
                    }`}
                  >
                    <h3>NCSBN ID</h3>
                    <h4>{currentUser?.ncsbnId}</h4>
                  </div>
                  {currentUser?.licenseType && (
                    <div className={`AccountVerifyCardInfoItem`}>
                      <h3>License Type</h3>
                      <h4>{currentUser?.licenseType}</h4>
                    </div>
                  )}
                  {currentUser?.licenseState && (
                    <div
                      className={`AccountVerifyCardInfoItem ${
                        currentUser?.licenseType
                          ? "AccountVerifyCardInfoSingleItem"
                          : ""
                      }`}
                    >
                      <h3>State</h3>
                      <h4>{currentUser?.licenseState}</h4>
                    </div>
                  )}
                </>
              )}
          </div>
          <div className="AccountVerifyCardInfoFooter">
            <div className="AccountVerifyCardInfoFooterText">Status</div>
            <div className="AccountVerifyCardInfoFooterStatus StatusActive">
              ACTIVE
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={stepBack}
        style={{ justifyContent: "flex-end", width: "100%", paddingTop: 18 }}
        className="AccountVerifyStepQuestion"
      >
        NOT YOU? TRY AGAIN <ReloadOutlined />
      </div>
    </div>
  );
}
