import React, { useEffect } from "react";
import { PageFooterHeight } from "../NavFooter/NavFooter";
import { useDeviceType } from "../../hooks/useDeviceType";
import "./Dialog.css"
export const GreetingsProfileDialog = ({ userName, displayDialog }) => {
  const deviceType = useDeviceType();

  return (
    <div
      className="NavFooterDialogContainer"
      style={{
        bottom: PageFooterHeight + 25,
        display: displayDialog ? "flex" : "none",
        width: deviceType === "Desktop" ? "30%" : "90%",
      }}>
      <span className="NavFooterDialogWelcomeTitle">{`Thanks, ${userName}!`}</span>
      <span className="NavFooterDialogTitle">Your Profile is up to date!</span>
    </div>
  )
}