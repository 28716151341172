import handShake from "../assets/handShake.png";
import PublicLayout from "../layoutsHOC/PublicLayout";
import Login from "../components/Stytch/StytchLogin";
import TokenAuthenticator from "../components/Stytch/TokenAuthenticator";
import { SignUpFooterText } from "./SignUp/components/SignupFooter";

export default function LoginPage() {
  const title = (
    <>
      <img width={40} src={handShake} alt="Shake Hands" /> Welcome to Rubie
    </>
  );

  const subtitle = "Join a thriving network of nurses just like you.";

  return (
    <PublicLayout title={title} subtitle={subtitle}>
      <TokenAuthenticator>
        <Login />
      </TokenAuthenticator>
    </PublicLayout>
  );
}
