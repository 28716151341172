import React from "react";
import { Form, Input, Select } from "antd";
import { useAlert } from "../../../hooks/useAlert";

import globe from "../../../assets/globe.svg";

import { AMERICAN_STATES_AND_TERRITORIES } from "../../../helpers/states";
import { RubieUserService } from "../../../services/RubieUser";

export function AccountVerifyStep2({
  form,
  onSubmitSuccess,
  setSubmitForm,
  currentUser,
  loading,
}) {
  const { showAlert } = useAlert();

  React.useEffect(() => {
    if (currentUser) {
      form.setFieldsValue({
        city: currentUser?.currentCity,
        state: currentUser?.currentState,
      });
    }
  }, [currentUser, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const { city, state } = values;

      const result = await RubieUserService.updateLocation({
        currentCity: city,
        currentState: state,
      });

      if (result.errors?.length > 0) {
        showAlert("error", "Failed to update location. Please try again.");
      } else {
        showAlert("success", "Your location was updated!");
        onSubmitSuccess(values);
      }
    } catch (error) {
      if (error.errorFields) {
        // Form validation error
        return;
      }
      console.error("Error updating location:", error);
      showAlert(
        "error",
        "An error occurred while updating location. Please try again."
      );
    }
  };

  React.useEffect(() => {
    setSubmitForm(() => handleSubmit);
  }, [setSubmitForm]);

  return (
    <div className="AccountVerifyStep">
      <img
        width={40}
        className="AccountVerifyStepImg"
        src={globe}
        alt="Globe"
      />
      <div className="AccountVerifyStepTitle">Where Are You Located?</div>
      <div className="AccountVerifyStepDescription">
        Your location helps us connect you with local resources and events.{" "}
      </div>
      <Form.Item
        extra={
          <div className="AccountVerifyStepInfo">
            Currently, Rubie is available only for nurses in the US. Stay tuned
            for future updates!
          </div>
        }
        name="country"
        label="Country"
        initialValue="United States"
        rules={[{ required: true }]}
      >
        <Select
          options={[
            {
              value: "United States",
              label: "United States",
            },
          ]}
          placeholder="Select Country"
          size="large"
          disabled
        />
      </Form.Item>
      <Form.Item name="city" label="City" rules={[{ required: true }]}>
        <Input
          size="large"
          placeholder="e.g., San Francisco"
          disabled={loading}
        />
      </Form.Item>
      <Form.Item name="state" label="State" rules={[{ required: true }]}>
        <Select disabled={loading} size="large" placeholder="Select a state">
          {AMERICAN_STATES_AND_TERRITORIES.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
}
