import React, { useEffect } from "react";
import { PageFooterHeight } from "../NavFooter/NavFooter";
import { useDeviceType } from "../../hooks/useDeviceType";
import { Button } from "@nextui-org/react";
import "./Dialog.css";
export const CompleteProfileDialog = ({
  currentUser,
  displayDialog,
  setDisplayDialog,
  onStartHandleClick,
}) => {
  const deviceType = useDeviceType();

  const userName = currentUser?.firstName ?? "";

  // If the user is not verified, display the dialog
  useEffect(() => {
    if (currentUser && !currentUser?.specialty) {
      // If the user has reminded me later, and it was less than 24 hours ago, don't display the dialog
      const remindMeLaterSetAt = localStorage.getItem("remindMeLaterSetAt");
      if (
        remindMeLaterSetAt &&
        new Date(remindMeLaterSetAt) >
          new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
      ) {
        return;
      }

      setDisplayDialog(true);
    }
  }, [currentUser]);

  const remindMeLaterClick = () => {
    setDisplayDialog(false);

    // save this to local storage
    localStorage.setItem("remindMeLaterSetAt", new Date().toISOString());
  };

  return (
    <div
      className="NavFooterDialogContainer"
      style={{
        bottom: PageFooterHeight + 25,
        display: displayDialog ? "flex" : "none",
        width: deviceType === "Desktop" ? "60%" : "90%",
      }}
    >
      <span className="NavFooterDialogWelcomeTitle">{`Welcome, ${userName}!`}</span>
      <span className="NavFooterDialogTitle">
        Let’s finish setting up your profile
      </span>
      <div className="flex mt-4 justify-between items-center">
        <span
          className="NavFooterDialogReminderBtn"
          onClick={remindMeLaterClick}
        >
          Remind me later
        </span>
        <Button
          size="small"
          type="default"
          iconPosition="end"
          onClick={onStartHandleClick}
          className={`NavFooterDialogStartBtnContainer`}
        >
          <span className="NavFooterDialogStartBtn">START</span>
        </Button>
      </div>
    </div>
  );
};
