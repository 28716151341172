import React from "react";
import { Progress } from "@nextui-org/react";

export default function ProgressLoader() {
  // This is contained in .raf-loading-indicator when rendered in a feed timeline's "LoadingIndicator", the override class for which is editable in index.css
  return (
    <div
      style={{
        width: "100vw",
        maxWidth: "720px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        paddingTop: 6,
        paddingBottom: 30,
      }}
    >
      <Progress
        size="sm"
        radius="sm"
        isIndeterminate
        classNames={{
          base: "max-w-md mt-2 mb-4",
          track: "bg-transparent",
          indicator: "bg-rubie-green",
        }}
        aria-label="Loading..."
      />
    </div>
  );
}
