import React from "react";
import './Settings.css';
import { Header } from "./components/Header";
import { Form, Input, notification } from "antd";
import { RubieButton } from "../../components/RubieButton/RubieButton";
import { useNavigate } from "react-router-dom";

export function SettingsChangePasswordPage() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleFinish = (data) => {
    if (data.password !== data.confirmPassword) {
      notification.error({ message: 'Passwords do not match. Please make sure both passwords are identical.' });
    }
  }

  return (
    <div className="SettingsContainer">
      <Header title='Change Password' showTitle />
      <div style={{ border: 'none' }} className="SettingsContent">
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={handleFinish}
          style={{ maxWidth: 600, width: '100%' }}
          validateMessages={{ required: 'Required field!' }}
        >
          <Form.Item name="password" label="Password" rules={[{ required: true }]}>
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item name="confirmPassword" label="Confirm Password" rules={[{ required: true }]}>
            <Input.Password size="large" />
          </Form.Item>
          <div className="SettingsButtons">
            <RubieButton variant='ghost' onPress={() => navigate('/settings')}>Cancel</RubieButton>
            <RubieButton type='submit'>Continue</RubieButton>
          </div>
        </Form>
      </div>
    </div>
  )
}