import React, { useState } from "react";
import { FlatFeed, useStreamContext } from "react-activity-feed";

import { AuthenticatedLayout } from "../../layoutsHOC/AuthenticatedLayout";
import { ProfileHeader } from "../../components/Profile/ProfileHeader";

import {
  CREATE_POST_PATH,
  FeedGroupAndGroupIdFromFeedId,
  USER_PROFILE_INVITES_PATH,
  USER_PROFILE_PATH,
} from "../../routes";
import { useNavigate, useParams } from "react-router-dom";
import { SlideTabs } from "../../components/Tabs/Tabs";
import { useStreamFollowers } from "../../services/StreamFollowers";
import Post from "../../components/Post";
import ProgressLoader from "../../components/ProgressLoader";
import InfiniteScrollPaginator from "../../components/InfiniteScrollPaginator";
import { EmptyPostState } from "../../components/Post/EmptyPost";
import { InviteBox } from "../../components/Invite/InviteBox";
import { InviteTracker } from "../../components/Invite/InviteTracker";
import { GET_CURRENT_USER_WITH_INVITES_QUERY } from "../../graphql/user";
import WithQuery from "../../components/WithQuery";

function ProfilePageInner({ data, loading, error, refetch }) {
  const { user } = useStreamContext();
  const navigate = useNavigate();

  const currentUser = data?.currentUser;

  const [tabIndex, setTabIndex] = useState(0);
  let { feedId, externalUserId } = useParams();

  const tabOptions = [
    { label: "Posts", value: USER_PROFILE_PATH },
    { label: "Invites", value: USER_PROFILE_INVITES_PATH },
  ];

  const onTabSelect = (index) => {
    setTabIndex(index);
  };

  const { followUser, unfollowUser, isFollowingUserId } = useStreamFollowers();

  const { feedGroup, feedGroupId } = FeedGroupAndGroupIdFromFeedId({
    feedId,
    user,
    externalUserId,
  });

  return (
    <div style={{ maxWidth: "720px", width: "100%" }}>
      <ProfileHeader
        userId={user?.full?.id}
        name={user?.full?.data?.name}
        specialty={user?.full?.data?.specialty}
        specialtyIcon={user?.full?.data?.specialty_icon}
        yearsOfExperience={user?.full?.data?.years_of_experience}
        isCurrentUser={true}
      />
      <div className="pt-2 pb-2">
        <SlideTabs
          options={tabOptions}
          selectedIndex={tabIndex}
          onClick={onTabSelect}
        />
      </div>
      {tabIndex === 0 ? (
        <FlatFeed
          notify
          feedGroup={feedGroup}
          userId={feedGroupId}
          options={{
            limit: 5,
            withOwnReactions: true,
            withReactionCounts: true,
          }}
          // make sure the feed is updated when a post is deleted
          Placeholder={
            <EmptyPostState
              text="POST SOMETHING"
              onClick={() => navigate(CREATE_POST_PATH)}
            />
          }
          LoadingIndicator={ProgressLoader}
          Paginator={InfiniteScrollPaginator}
          Activity={({ activity }) => (
            <Post
              currentStreamUser={user}
              key={activity?.id}
              activity={activity}
              isFollowingUserId={isFollowingUserId}
              followUser={followUser}
              unfollowUser={unfollowUser}
            />
          )}
        />
      ) : (
        <>
          <InviteBox currentUser={currentUser} refreshParent={refetch} />
          <InviteTracker currentUser={currentUser} />
        </>
      )}
    </div>
  );
}

function ProfilePageAuthWrapped(props = {}) {
  return (
    <AuthenticatedLayout {...props} hideHeader={true}>
      <ProfilePageInner {...props} />
    </AuthenticatedLayout>
  );
}

export default function ProfilePage() {
  return WithQuery(
    ProfilePageAuthWrapped,
    GET_CURRENT_USER_WITH_INVITES_QUERY,
    {},
    { refetchQueries: [{ query: GET_CURRENT_USER_WITH_INVITES_QUERY }] }
  )();
}
