import React from 'react';
import pencil from '../../../assets/pencil.svg';
import { Form, Input } from 'antd';

export function SignUpStep1() {
  return (
    <div className='SignUpStep'>
      <img width={40} className='SignUpStepImg' src={pencil} alt='Pencil' />
      <div className='SignUpStepTitle'>Tell Us About Yourself</div>
      <div className='SignUpStepDescription'>Tell us a bit about yourself so we can personalize your experience!</div>
      <Form.Item name="firstName" label="First Name" rules={[{ required: true }]}>
        <Input placeholder="Jane" size='large' />
      </Form.Item>
      <Form.Item name="lastName" label="Last Name" rules={[{ required: true }]}>
        <Input placeholder="Collins" size='large' />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
        <Input placeholder="jane.collins@gmail.com" size='large' />
      </Form.Item>
    </div>
  )
}