import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { isNil } from "lodash";

import "./Tabs.css";

export const SlideTabs = ({ options, onClick, selectedIndex }) => {
  const [position, setPosition] = useState({
    left: 0,
    width: 0,
    opacity: 0,
  });

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ul
      className="relative mx-auto flex w-fit rounded-full p-1 TabsContainer mb-2 mt-1"
      style={{ paddingLeft: 2, paddingRight: 2 }}
    >
      {options.map((item, index) => (
        <Tab
          isSelected={index === selectedIndex}
          key={index}
          onClick={onClick}
          index={index}
          setPosition={setPosition}
          windowSize={windowSize}
        >
          {item.label}
        </Tab>
      ))}
      <Cursor position={position} />
    </ul>
  );
};

const Tab = ({
  children,
  setPosition,
  isSelected,
  onClick,
  index,
  windowSize,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    // Also want to trigger on window resize
    if (!isNil(ref.current) && isSelected) {
      const { width } = ref.current.getBoundingClientRect();

      setPosition({
        left: ref.current.offsetLeft,
        width,
        opacity: 1,
      });
    }
  }, [ref, isSelected, setPosition, windowSize]);

  return (
    <li
      ref={ref}
      onClick={() => {
        onClick(index);
      }}
      className={`relative z-10 Tab block cursor-pointer px-3 py-1.5 text-white mix-blend-difference md:px-5 md:py-3 md:text-base ${
        isSelected && "SelectedTab"
      }`}
    >
      {children}
    </li>
  );
};

const Cursor = ({ position }) => {
  return (
    <motion.li
      animate={{
        ...position,
      }}
      className="absolute z-0 CursorContainer md:h-12 pl-0"
    />
  );
};
