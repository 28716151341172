import React from "react";

import Lottie from "lottie-react";
import Confetti from '../../assets/lottie/ConfettiAnimation.json';

import "./ConfettiAnimation.css"
export const ConfettiAnimation = () => {
  return (
    <div className="w-full h-full flex" style={{ position: "absolute" }}>
      <Lottie animationData={Confetti} className="AnimationRight" />
      <Lottie animationData={Confetti} className="AnimationLeft" />
    </div>)
};
