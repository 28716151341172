import * as Sentry from "@sentry/react";
import { useState, useEffect, useCallback } from "react";
import { useStreamContext } from "react-activity-feed";
import { FEED_ID_TIMELINE, FEED_ID_USER } from "../routes";

export const useStreamFollowers = () => {
  const { user, client } = useStreamContext();
  const [followingIdHash, setFollowingIdsHash] = useState({});

  const fetchFollowing = useCallback(async () => {
    if (!client || !user) {
      console.log("client or user not found");
      return;
    }
    try {
      const response = await client.feed(FEED_ID_TIMELINE, user.id).following();
      const followersHash = {};
      response.results.forEach((followedUser) => {
        followersHash[followedUser.target_id.replace("user:", "")] = true;
      });

      setFollowingIdsHash(followersHash);
    } catch (error) {
      console.error("Error fetching following:", error);
      Sentry.captureException(error);
    }
  }, [client, user]);

  const followUser = useCallback(
    async (newFollowUserId) => {
      if (!client || !user) return;
      try {
        await client
          .feed(FEED_ID_TIMELINE, user.id)
          .follow(FEED_ID_USER, newFollowUserId);
        await fetchFollowing();
      } catch (error) {
        console.error("Error following user:", error);
        Sentry.captureException(error);
      }
    },
    [client, user, fetchFollowing]
  );

  const unfollowUser = useCallback(
    async (newFollowUserId) => {
      if (!client || !user) return;
      try {
        await client
          .feed(FEED_ID_TIMELINE, user.id)
          .unfollow(FEED_ID_USER, newFollowUserId);
        await fetchFollowing();
      } catch (error) {
        console.error("Error unfollowing user:", error);
        Sentry.captureException(error);
      }
    },
    [client, user, fetchFollowing]
  );

  const isFollowingUserId = (userId) => {
    if (!userId) {
      return false;
    }

    if (!followingIdHash) {
      return false;
    }

    return !!followingIdHash[userId];
  };

  useEffect(() => {
    if (client && user) {
      fetchFollowing();
    }
  }, [fetchFollowing, client, user]);

  return {
    followingIdHash,
    followUser,
    unfollowUser,
    isFollowingUserId,
    loading: !client || !user,
  };
};
