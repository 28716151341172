import React from "react";
import { Button } from "@nextui-org/react";
import { shareOnMobile } from "react-mobile-share";
import { useNavigate } from "react-router-dom";
import { isNil } from "lodash";

import { ReactComponent as Share } from "../../assets/Export.svg";

import "./PostFooter.css";
import {
  FEED_ID_USER,
  GetFullUrlWithPath,
  GetThreadUrlPath,
} from "../../routes";

export default function ShareButton({ activity, hideShareButton }) {
  const navigate = useNavigate();

  const postId = activity.id;
  const topic = activity?.topic?.toLowerCase() || FEED_ID_USER;

  const threadUrl = GetFullUrlWithPath(
    GetThreadUrlPath({ feedId: topic, activityId: postId })
  );

  const handleShareButtonClick = () => {
    shareOnMobile({
      // text: "Check out this post on Rubie",
      url: threadUrl,
      title: "Check out this post on Rubie",
    });
  };

  if (!isNil(hideShareButton)) {
    return null;
  }

  if (hideShareButton) {
    return null;
  }

  return (
    <Button
      onClick={handleShareButtonClick}
      radius="none"
      color="transparent"
      className="border-r-1 border-black min-w-3"
      startContent={<Share />}
    />
  );
}
