import React from "react";
import { Drawer } from "antd";
import { RubieButton } from "../RubieButton";
import { shareOnMobile } from "react-mobile-share";
import { GetActiveAppUrl } from "../../helpers/config";
import { CLAIM_INVITE_PATH } from "../../routes";

export const generateInviteUrl = (code) => {
  return `${GetActiveAppUrl()}${CLAIM_INVITE_PATH}?code=${code}`;
};

export const shareInvite = (invite) => {
  shareOnMobile({
    title: "Join Rubie",
    text: `Join Rubie with my invite code: ${invite.code}`,
    url: generateInviteUrl(invite.code),
  });
};

export const ShareInviteModal = ({
  setOpenDrawer,
  openDrawer,
  handlers,
  invite,
}) => {
  if (!invite) {
    return null;
  }

  return (
    <Drawer
      title="Share Invite"
      placement="bottom"
      onClose={() => setOpenDrawer(false)}
      open={openDrawer}
      height={300}
      {...handlers}
    >
      <div className="flex flex-col items-center">
        <h3 className="text-xl font-bold mb-4">
          Your invite to {invite.recipientName} is ready to share!
        </h3>
        <p className="mb-4 text-center">
          Join Rubie with my invite code: {invite.code}
          <br />
          {generateInviteUrl(invite.code)}
        </p>
        <RubieButton onPress={() => shareInvite(invite)} className="mt-4">
          Share Invite
        </RubieButton>
      </div>
    </Drawer>
  );
};
