import React from "react";
import { ReactComponent as SealCheck } from "../../../assets/sealCheck.svg";
import { ConfettiAnimation } from "../../../components/Confetti/ConfettiAnimation";

export function AccountVerifyStepFinal() {
  return (
    <div className="AccountVerifyStep6">
      <div>
        <SealCheck />
        Welcome to Rubie
      </div>
      <div>&nbsp;</div>

      <ConfettiAnimation />
    </div>
  );
}
