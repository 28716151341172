import React, { useState } from 'react';
import './PasswordStrength.css'

export function PasswordStrength({ validate }) {
  const [validateTypes] = useState({ 'Too weak': 1, 'Weak': 2, 'Medium': 3, 'Strong': 4 })

  return (
    <div className='PasswordStrengthContainer'>
      <div className='PasswordStrengthSteps'>
        <div className={`PasswordStrengthStep ${(validate && validateTypes[validate]) > 0 ? `PasswordStrengthStepActived${validateTypes[validate]}` : ''}`}></div>
        <div className={`PasswordStrengthStep ${(validate && validateTypes[validate]) > 1 ? `PasswordStrengthStepActived${validateTypes[validate]}` : ''}`}></div>
        <div className={`PasswordStrengthStep ${(validate && validateTypes[validate]) > 2 ? `PasswordStrengthStepActived${validateTypes[validate]}` : ''}`}></div>
        <div className={`PasswordStrengthStep ${(validate && validateTypes[validate]) > 3 ? `PasswordStrengthStepActived${validateTypes[validate]}` : ''}`}></div>
      </div>
      <div className='PasswordStrengthValidate'>
        {validate}
      </div>
    </div>
  )
}