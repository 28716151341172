import React, { createContext, useEffect, useState } from 'react';
import { Alert } from 'antd';
import { useDeviceType } from '../hooks/useDeviceType';

export const AlertContext = createContext({
  showAlert: () => { },
});

export const AlertProvider = ({ children }) => {
  const [alertMessage, setAlertMessage] = useState([]);
  const deviceType = useDeviceType();

  const hideAlert = (index) => {
    setAlertMessage((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage((prevItems) => {
        if (prevItems.length > 0) {
          return prevItems.filter((_, i) => i !== 0);
        }
        // clearInterval(interval);
        return prevItems;
      });
    }, 3000)
  }, [alertMessage]);

  const contextValue = {
    showAlert: (type, message) => {
      const alertMessage = {
        type,
        message,
      };


      setAlertMessage((prev) => [...prev, alertMessage]);
    },
  };

  return (
    <AlertContext.Provider value={contextValue}>
      <div
        style={{
          position: 'absolute',
          paddingTop: deviceType === 'Mobile' ? 30 : 140,
          right: `25%`,
          zIndex: 10
        }}
      >
        {alertMessage.map((alert, index) => (
          <Alert
            key={index}
            message={alert.message}
            style={{ margin: 0, width: '100%' }}
            type={alert.type}
            onClose={() => hideAlert(index)}
            closable
          >
            <div>{alert.message}</div>
          </Alert>
        ))}
      </div>
      {children}
    </AlertContext.Provider>
  );
};
