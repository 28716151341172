import React from "react";
import { Form, Input } from "antd";
import pencil from "../../../assets/pencil.svg";

import { RubieUserService } from "../../../services/RubieUser";
import { useAlert } from "../../../hooks/useAlert";

export function AccountVerifyStep1({
  form,
  onSubmitSuccess,
  setSubmitForm,
  currentUser,
  loading,
}) {
  const { showAlert } = useAlert();

  React.useEffect(() => {
    if (currentUser) {
      form.setFieldsValue({
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
      });
    }
  }, [currentUser, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const { firstName, lastName } = values;
      const result = await RubieUserService.updateUserName({
        firstName,
        lastName,
      });

      if (result.errors?.length > 0) {
        showAlert(
          "error",
          "Failed to update personal information. Please try again."
        );
      } else {
        showAlert("success", "Your personal information was updated!");
        onSubmitSuccess();
      }
    } catch (error) {
      if (error.errorFields) {
        // Form validation error
        return;
      }
      console.error("Error updating personal information:", error);
      showAlert(
        "error",
        "An error occurred while updating personal information. Please try again."
      );
    }
  };

  React.useEffect(() => {
    setSubmitForm(() => handleSubmit);
  }, [setSubmitForm]);

  return (
    <div className="SignUpStep">
      <img width={40} className="SignUpStepImg" src={pencil} alt="Pencil" />
      <div className="SignUpStepTitle">Tell Us About Yourself</div>
      <div className="SignUpStepDescription">
        Tell us a bit about yourself so we can personalize your experience!
      </div>
      <Form.Item
        name="firstName"
        label="First Name"
        rules={[{ required: true }]}
      >
        <Input placeholder="Jane" size="large" disabled={loading} />
      </Form.Item>
      <Form.Item name="lastName" label="Last Name" rules={[{ required: true }]}>
        <Input placeholder="Collins" size="large" disabled={loading} />
      </Form.Item>
    </div>
  );
}
