import React from "react";
import "./Settings.css";
import { Header } from "./components/Header";
import { ReactComponent as Linkedin } from "../../assets/linkedinLogo.svg";
import { Button } from "antd";
import { USER } from "../../helpers/fakeData";

export function SettingsConnectedAccountsPage() {
  return (
    <div className="SettingsContainer">
      <Header title='Connected Accounts' showTitle />
      <div style={{ border: 'none' }} className="SettingsContent">
        <div className="SettingsCards">
          <div className="SettingsCard">
            <div className="SettingsCardItem">
              <div className="SettingsCardAccount"><Linkedin />Linkedin</div>
              <Button type="ghost">
                {USER.connectedLinkedin ?
                  <div className="SettingsCardConnected">Connected</div> :
                  <div className="SettingsCardConnect">Connect</div>
                }
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}