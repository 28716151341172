import React, { useState } from "react";
import moment from "moment";
import { Avatar } from "@nextui-org/avatar";
import "./PostHeader.css";
import avatarImage from "../../assets/avatarImage.svg";
import { getInitialLetters } from "../../helpers/user";
import { isNil } from "lodash";
import { humanizeTimestamp } from "react-activity-feed/dist/utils";
import { FollowButton } from "../FollowButton/FollowButton";
import { useNavigate } from "react-router-dom";
import { GetExternalUserProfilePath, USER_PROFILE_PATH } from "../../routes";

export function PostHeader({
  currentStreamUser,
  actor,
  author,
  specialty,
  specialtyIcon,
  yearsOfExperience,
  isFollowing,
  followUser,
  unfollowUser,
}) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // TODO: get this from a mapping based on specialty string

  const onFollowClick = async () => {
    setLoading(true);
    if (followUser) {
      await followUser(actor?.id);
    }
    setLoading(false);
  };

  const onUnfollowClick = async () => {
    setLoading(true);
    if (unfollowUser) {
      await unfollowUser(actor?.id);
    }
    setLoading(false);
  };

  const renderFollowButton = () => {
    if (isNil(actor)) {
      return null;
    }

    // if actor is the current user, don't show the follow button
    if (actor.id === currentStreamUser?.id) {
      return null;
    }

    return isFollowing ? (
      <FollowButton
        isFollowing={isFollowing}
        loading={loading}
        onClick={onUnfollowClick}
        text="Following"
      />
    ) : (
      <FollowButton
        isFollowing={isFollowing}
        loading={loading}
        onClick={onFollowClick}
        text="Follow"
      />
    );
  };

  const onProfileClickHandle = () => {
    // if actor is the current user, don't go to the settings
    if (actor.id === currentStreamUser?.id) {
      navigate(USER_PROFILE_PATH)
    } else {
      const path = GetExternalUserProfilePath({ userId: actor.id });
      navigate(path);
    }
  };

  return (
    <div className="PostHeaderContainer bg-white">
      <div className="PostHeaderAvatarBorderTop PostHeaderBorderLeftWindow" />
      <div
        className={`PostHeaderAvatar PostHeaderBorderLeftWindow ${actor.id !== currentStreamUser?.id ? "cursor-pointer" : ""
          }`}
        onClick={onProfileClickHandle}
      >
        <Avatar src={avatarImage} style={{ backgroundColor: "transparent" }} />
        <h3
          className="uppercase PostHeaderLetters"
          style={{ position: "absolute" }}
        >{`${getInitialLetters(author)}`}</h3>
      </div>
      <div style={{ flex: 1, backgroundColor: "#FAF7E8" }}>
        <div className={"PostHeaderNameContainer PostHeaderBorderRightWindow "}>
          <h1
            className={`PostHeaderTitle ${actor.id !== currentStreamUser?.id ? "cursor-pointer" : ""
              }`}
            onClick={onProfileClickHandle}
          >
            {author}
          </h1>
          {renderFollowButton()}
        </div>
        <div className="PostHeaderDescriptionContainer PostHeaderBorderRightWindow">
          <span
            className="PostHeaderDescription"
            onClick={onProfileClickHandle}
          >
            {specialtyIcon && <span className="mr-2">{specialtyIcon}</span>}
            {specialty && <span className="mr-2">{specialty}</span>}
            {!isNil(yearsOfExperience) && (
              <>
                <span className="mr-2">|</span>{" "}
                <span>
                  {yearsOfExperience ? yearsOfExperience + " Years" : null}
                </span>
              </>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
