import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import posthog from "posthog-js";

import { IsProduction } from "../helpers/config";
import { LOGIN_PATH, VERIFY_ACCOUNT_PATH, WAITLIST_PATH } from "../routes";
import WithQuery from "../components/WithQuery";
import { CURRENT_USER_AUTH_QUERY } from "../graphql/user";
import { Loading } from "../components/Loading/Loading";
import { fullName } from "../helpers/user";

function AuthenticatedRouteInner({ data, loading }) {
  const currentUser = data?.currentUser;

  if (loading) {
    return (
      <div
        style={{
          backgroundColor: "#FAF7E8",
        }}
        className="flex justify-center"
      >
        <Loading />
      </div>
    );
  }

  if (currentUser) {
    if (IsProduction() && currentUser.id) {
      posthog.identify(currentUser.id, {
        email: currentUser.email,
        name: fullName(currentUser),
      });
    }
    if (currentUser?.isBanned) {
      return (
        <Navigate
          to={{
            pathname: WAITLIST_PATH,
          }}
        />
      );
    }
    if (!currentUser?.accountVerified) {
      return (
        <Navigate
          to={{
            pathname: VERIFY_ACCOUNT_PATH,
          }}
        />
      );
    }
    return <Outlet />;
  }

  return (
    <Navigate
      to={{
        pathname: LOGIN_PATH,
      }}
    />
  );
}

export default function AuthenticatedRoute() {
  return WithQuery(AuthenticatedRouteInner, CURRENT_USER_AUTH_QUERY, {}, {})();
}
