import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import ProgressLoader from "./ProgressLoader";

function InfiniteScrollPaginator(props) {
  const { children, hasNextPage, loadNextPage, refreshing } = props;

  const [isLoading, setIsLoading] = React.useState(refreshing);

  React.useEffect(() => {
    setIsLoading(refreshing);
  }, [refreshing]);

  const loadMoreContent = () => {
    setIsLoading(true);
    loadNextPage();
  };

  return (
    <div
      className="infinite-scroll-container-div"
      style={{
        width: "100%",
        maxWidth: "720px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 6,
        paddingBottom: 6,
      }}
    >
      <InfiniteScroll
        {...props}
        dataLength={children?.length || 0}
        hasMore={hasNextPage}
        next={loadMoreContent}
        style={{
          width: "100%",
        }}
        scrollThreshold={0.8}
        scrollableTarget="page-scroll-container-div"
      >
        {children}
        {(isLoading || refreshing) && (
          <>
            <ProgressLoader />
            <div style={{ height: 24, width: "100%" }} />
          </>
        )}
      </InfiniteScroll>
    </div>
  );
}

export default InfiniteScrollPaginator;
