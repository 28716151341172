import React, { useState, useCallback } from "react";
import { Form } from "antd";
import { AccountVerifyStepFinal } from "./components/AccountVerifyStepFinal";
import { useNavigate } from "react-router-dom";
import { AccountVerifyHeader } from "./components/AccountVerifyHeader";
import { AccountVerifyFooter } from "./components/AccountVerifyFooter";

import { AccountVerifyStep1 } from "./components/AccountVerifyStep1";
import { AccountVerifyStep2 } from "./components/AccountVerifyStep2";
import { AccountVerifyStep3 } from "./components/AccountVerifyStep3";
import { AccountVerifyStep4 } from "./components/AccountVerifyStep4";

import "./AccountVerify.css";
import { LOGIN_PATH, DISCOVER_PATH } from "../../routes";
import WithQuery from "../../components/WithQuery";
import { CURRENT_USER_QUERY } from "../../graphql/user";

function AccountVerifyPageInner({ data, loading, error, refetch }) {
  const navigate = useNavigate();

  const currentUser = data?.currentUser;

  React.useEffect(() => {
    if (loading) return;

    if (
      data?.currentUser &&
      data.currentUser?.accountVerifiedAt &&
      new Date(data.currentUser?.accountVerifiedAt).getTime() <
        Date.now() - 120000
    ) {
      // Don't nav right away, let the user see the confetti
      setStep(5);
    }

    if (!data?.currentUser) {
      navigate(LOGIN_PATH);
    }
  }, [data, navigate, loading]);

  // Start form

  const [form] = Form.useForm();
  const [step, setStep] = useState(1);

  const [submitForm, setSubmitForm] = useState(() => {});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleStepSuccess = useCallback(() => {
    refetch();
    setStep((step) => step + 1);
  }, [refetch]);

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    await submitForm();
    setIsSubmitting(false);
  }, [submitForm]);

  const validateMessages = {
    required: "This is required!",
    types: {
      email: "This is not a valid email!",
    },
  };

  // Render steps

  if (step === 5) {
    setTimeout(() => {
      navigate("/");
    }, 3000);

    return <AccountVerifyStepFinal />;
  }

  return (
    <Form
      layout="vertical"
      form={form}
      name="control-hooks"
      style={{ maxWidth: 600 }}
      validateMessages={validateMessages}
      onFinish={handleSubmit}
    >
      <div
        style={{
          backgroundColor: "#FBFAF2",
          minWidth: "100vw",
          minHeight: "100vh",
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <AccountVerifyHeader step={step} setStep={setStep} />
          <div
            style={{
              flex: 1,
            }}
          >
            <div className="AccountVerifyStepsContainer">
              {step === 1 && (
                <AccountVerifyStep1
                  form={form}
                  currentUser={currentUser}
                  onSubmitSuccess={handleStepSuccess}
                  setSubmitForm={setSubmitForm}
                  loading={isSubmitting || loading}
                />
              )}
              {step === 2 && (
                <AccountVerifyStep2
                  form={form}
                  currentUser={currentUser}
                  onSubmitSuccess={handleStepSuccess}
                  setSubmitForm={setSubmitForm}
                  loading={isSubmitting || loading}
                />
              )}
              {step === 3 && (
                <AccountVerifyStep3
                  form={form}
                  currentUser={currentUser}
                  onSubmitSuccess={handleStepSuccess}
                  setSubmitForm={setSubmitForm}
                  loading={isSubmitting || loading}
                />
              )}
              {step === 4 && (
                <AccountVerifyStep4
                  form={form}
                  currentUser={currentUser}
                  onSubmitSuccess={handleStepSuccess}
                  setSubmitForm={setSubmitForm}
                  loading={isSubmitting || loading}
                  stepBack={() => setStep((step) => step - 1)}
                />
              )}
            </div>
          </div>
          <AccountVerifyFooter loading={isSubmitting} step={step} />
        </div>
      </div>
    </Form>
  );
}

export default function AccountVerifyPage() {
  return WithQuery(
    AccountVerifyPageInner,
    CURRENT_USER_QUERY,
    {},
    {
      refetchQueries: [CURRENT_USER_QUERY],
    }
  )();
}
