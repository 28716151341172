import React, { useState } from "react";
import moment from "moment";
import { Button } from "@nextui-org/button";

import {
  humanizeTimestamp,
  textRenderer,
  useOnClickUser,
} from "react-activity-feed/dist/utils";

import "./CommentItem.css";

import { Avatar } from "../Avatar/Avatar";
import LikeButton from "../LikeButton";
import { useStreamContext } from "react-activity-feed";
import { useNavigate } from "react-router-dom";
import { GetExternalUserProfilePath, USER_PROFILE_PATH } from "../../routes";

export default function CommentItem({
  comment,
  isReply,
  onClickHashtag,
  onClickMention,
  onClickUser,
  // className,
  // style,
}) {
  const {
    user,
    created_at,
    data,
    children_counts,
    // latest_children,
    // own_children,
  } = comment;

  const [displayReplies, setDisplayReplies] = useState(false);
  const { user: currentStreamUser } = useStreamContext();
  const navigate = useNavigate();

  const handleUserClick = useOnClickUser(onClickUser);

  const reactionKind = "comment";
  const replyCount = children_counts?.[reactionKind ?? ""] || 0;

  const renderReplies = () => {
    if (isReply || replyCount === 0) return null;

    return (
      <div className="CommentRepliesContainer">
        {comment.replies?.map((item) => (
          <div
            key={item.id}
            className={`CommentShowRepliesContainer ${displayReplies && `CommentShowRepliesContainerVisible`
              }`}
          >
            <CommentItem
              comment={item}
              key={`reply-${item.id}`}
              isReply={true}
            />
          </div>
        ))}
        {comment.replies?.length > 0 && (
          <Button
            className={`CommentShowRepliesButton`}
            radius="none"
            color="transparent"
            onClick={() => setDisplayReplies(!displayReplies)}
          >
            <span>{`${displayReplies ? "Hide" : "View"} Replies (${comment.replies?.length ?? 0
              })`}</span>
          </Button>
        )}
      </div>
    );
  };

  const onProfileClickHandle = () => {
    handleUserClick?.(user);
    // if actor is the current user, don't go to the settings
    if (user.id === currentStreamUser?.id) {
      navigate(USER_PROFILE_PATH)
    } else {
      const path = GetExternalUserProfilePath({ userId: user.id });
      navigate(path);
    }
  };

  return (
    <div
      className={`CommentContainer 
      ${isReply ? "CommentReplyContainer" : ""} 
      ${replyCount > 0 ? "border-b-1" : "border-b-0"}`}
    >
      <div className="CommentBodyContainer">
        <Avatar
          // onClick={handleUserClick?.(user)}
          onClick={onProfileClickHandle}
          backgroundColor="#FFC3FE"
          name={user?.data?.name}
          size={"small"}
        />
        <div className="CommentBody">
          <span className="CommentAuthorText" onClick={onProfileClickHandle}>{user?.data?.name}</span>
          <span className="CommentMessageText">
            {textRenderer(
              data.text,
              "raf-comment-item",
              onClickMention,
              onClickHashtag
            )}
          </span>
          <div className="flex-row">
            <span className="CommentTimeText">
              {humanizeTimestamp(created_at, (time) => moment(time))}
            </span>
            {/* <Button
              size="sm"
              radius="none"
              color="transparent"
              fullWidth={false}
            >
              REPLY
            </Button> */}
          </div>
        </div>
        <div className="CommentLikes">
          <LikeButton reaction={comment} className={"border-0"} />
        </div>
      </div>
      {renderReplies()}
    </div>
  );
}
