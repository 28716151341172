import React from "react";
import "./StartBrowsing.css";
import { ReactComponent as Actions } from "../../assets/arrowAction.svg";
import career from "../../assets/career.png";
import lifestyle from "../../assets/lifestyle.png";
import wellness from "../../assets/wellness.png";
import { useNavigate } from "react-router-dom";
import {
  TOPIC_ID_LIFESTYLE,
  TOPIC_ID_CAREER,
  TOPIC_ID_BREAKROOM,
  FEED_TOPIC_TITLES,
  FEED_TOPIC_DESCRIPTIONS,
} from "../../helpers/feed";

import { GetFeedIdUrlPath } from "../../routes";
import { upperFirst } from "lodash";
import { useDeviceType } from "../../hooks/useDeviceType";

export function StartBrowsing() {
  const navigate = useNavigate();
  const deviceType = useDeviceType();

  const onClickHandle = (route) => {
    navigate(route);
  };

  return (
    <div className="StartBrowsingContainer">
      <div className="StartBrowsingCards mt-2">
        <Card
          imageSrc={career}
          topicId={TOPIC_ID_CAREER}
          onClick={onClickHandle}
          deviceType={deviceType}
        />
        <Card
          imageSrc={lifestyle}
          topicId={TOPIC_ID_LIFESTYLE}
          onClick={onClickHandle}
          deviceType={deviceType}
        />
        <Card
          imageSrc={wellness}
          topicId={TOPIC_ID_BREAKROOM}
          onClick={onClickHandle}
          deviceType={deviceType}
        />
      </div>
    </div>
  );
}

const Card = ({ topicId, imageSrc, onClick, deviceType }) => {
  return (
    <div
      onClick={() => onClick(GetFeedIdUrlPath({ feedId: topicId }))}
      className={`StartBrowsingCard StartBrowsing${upperFirst(topicId)}`}
    >
      <img src={imageSrc} alt={FEED_TOPIC_TITLES[topicId]} style={{ height: deviceType === 'Mobile' ? '110px' : 'auto' }} />

      <div className="StartBrowsingCardTitle">{FEED_TOPIC_TITLES[topicId]}</div>
      <div className="StartBrowsingCardDescriptionContainer">
        <div className="StartBrowsingCardDescription">
          {FEED_TOPIC_DESCRIPTIONS[topicId]}
        </div>
        <div className={`StartBrowsingCardIcon StartBrowsingCardIcon${upperFirst(topicId)}`}>
          <Actions style={{ fontSize: 12, color: `yellow` }} />
        </div>
      </div>
    </div>
  );
};
