import React, { useEffect, useState } from 'react';
import padlock from '../../../assets/padlock.svg';
import { Form, Input } from 'antd';
import { passwordStrength } from 'check-password-strength'
import { PasswordStrength } from '../../../components/PasswordStrength';

export function SignUpStep5() {
  const [password, setPassword] = useState();
  const [validate, setValidate] = useState();

  useEffect(() => {
    if (password != null && password.trim() !== '') {
      setValidate(passwordStrength(password).value)
    } else {
      setValidate(undefined);
    }
  }, [password])

  return (
    <div className='SignUpStep'>
      <img width={40} className='SignUpStepImg' src={padlock} alt='padlock' />
      <div className='SignUpStepTitle'>Secure Your Account</div>
      <div className='SignUpStepDescription'>Create a strong password to keep your account secure.</div>
      <Form.Item name="password" label="Password" rules={[{ required: true }]}>
        <Input.Password onChange={(event) => setPassword(event.target.value)} size='large' />
      </Form.Item>
      <PasswordStrength validate={validate} />
      <Form.Item name="confirmPassword" label="Confirm Password" rules={[{
        required: true, validator: (form, value) => {
          if (value == null || value.trim() === '') {
            return Promise.reject(new Error('This is required!'));
          } else if (password !== value) {
            return Promise.reject(new Error('Different passwords'));
          } else {
            return Promise.resolve();
          }
        }
      }]}>
        <Input.Password size='large' />
      </Form.Item>
    </div>
  )
}