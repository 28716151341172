import React from "react";
import { Button } from "antd";
import "./FollowButton.css";

export const FollowButton = ({ text, onClick, loading, isFollowing }) => {
  return (
    <Button
      size="small"
      type="default"
      iconPosition="end"
      loading={loading}
      onClick={onClick && onClick}
      className={`FollowButton2 uppercase ${
        isFollowing ? "FollowActived" : ""
      }`}
    >
      {text}
    </Button>
  );
};
