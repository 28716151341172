import React from "react";

import "./Invite.css";
import { isEmpty, isNil } from "lodash";
import { EmptyState } from "../EmptyState/EmptyState";
import { TrackerInviteItem } from "./TrackerInviteItem";

export const InviteTracker = ({ currentUser }) => {
  const pendingInvites = currentUser?.pendingInvites || [];
  const acceptedInvites = currentUser?.acceptedInvites || [];
  const allInvites = [...pendingInvites, ...acceptedInvites];

  return (
    <div className="InviteTrackerContainer">
      <span className="InviteTrackerTitle">Invite Tracker</span>

      {isEmpty(allInvites) || isNil(allInvites) ? (
        <EmptyState text="Your Friends Are Missing Out!" />
      ) : (
        <>
          {allInvites.map((invite, index) => (
            <TrackerInviteItem invite={invite} key={index} />
          ))}
        </>
      )}
    </div>
  );
};
