import React from "react";
import { useParams } from "react-router-dom";

import { SinglePost, useStreamContext } from "react-activity-feed";

import "./Thread.css";

import { AuthenticatedLayout } from "../../layoutsHOC/AuthenticatedLayout";

import Post from "../../components/Post";
import {
  ThreadHeader,
  ThreadHeaderHeight,
} from "../../components/Thread/ThreadHeader";
import {
  ThreadFooter,
  ThreadFooterHeight,
} from "../../components/Thread/ThreadFooter";

import ProgressLoader from "../../components/ProgressLoader";
import InfiniteScrollPaginator from "../../components/InfiniteScrollPaginator";
import { FeedGroupAndGroupIdFromFeedId } from "../../routes";
import { useStreamFollowers } from "../../services/StreamFollowers";

function ThreadPageInner() {
  let { activityId, feedId, externalUserId } = useParams();

  const { user } = useStreamContext();

  const { feedGroup, feedGroupId } = FeedGroupAndGroupIdFromFeedId({
    feedId,
    user,
    externalUserId,
  });

  const { followUser, unfollowUser, isFollowingUserId } = useStreamFollowers();

  if (!activityId || !feedId) return null;

  return (
    <>
      <SinglePost
        notify
        feedGroup={feedGroup}
        userId={feedGroupId}
        activityId={activityId}
        LoadingIndicator={ProgressLoader}
        Paginator={InfiniteScrollPaginator}
        options={{
          withRecentReactions: true,
          withOwnReactions: true,
          withReactionCounts: true,
          withOwnChildren: true,
        }}
        Activity={({ activity }) => (
          <>
            <Post
              currentStreamUser={user}
              key={activity?.id}
              activity={activity}
              showComments
              isFollowingUserId={isFollowingUserId}
              followUser={followUser}
              unfollowUser={unfollowUser}
            />
            <div style={{ height: `${ThreadFooterHeight}px` }} />
            <ThreadFooter activity={activity} />
          </>
        )}
      />
    </>
  );
}

export default function ThreadPage(props) {
  let { feedId } = useParams();

  const { feedGroupId } = FeedGroupAndGroupIdFromFeedId({
    feedId,
    user: null,
  });

  return (
    <AuthenticatedLayout
      hideHeader
      hideFooter
      customHeaderHeight={ThreadHeaderHeight}
      customHeader={<ThreadHeader feedId={feedGroupId} />}
    >
      <ThreadPageInner {...props} />
    </AuthenticatedLayout>
  );
}
