import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";

import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/dropdown";

import { ReactComponent as Actions } from "../../assets/threeDotsAction.svg";
import { useFeedContext } from "react-activity-feed";
import { CompareUrlPath, FEED_ID_USER, THREAD_PATH } from "../../routes";

export default function PostActionsButton({ activity, currentStreamUser }) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const feed = useFeedContext();

  if (currentStreamUser?.id !== activity?.actor?.id) {
    return null;
  }

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    feed
      .onRemoveActivity(activity.id)
      .then((r) => {
        console.log(r);
        // if we're on a thread page, redirect to the home page
        if (CompareUrlPath(window.location.pathname, THREAD_PATH)) {
          window.location.href = "/";
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsDeleteModalOpen(false);
      });
  };

  return (
    <>
      <Dropdown>
        <DropdownTrigger>
          <Button
            className={`border-l-1 border-black min-w-12`}
            radius="none"
            color="transparent"
          >
            <Actions />
          </Button>
        </DropdownTrigger>
        <DropdownMenu aria-label="Post actions">
          <DropdownItem key="delete" color="danger" onPress={handleDelete}>
            Delete
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        placement="center"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Confirm Deletion
              </ModalHeader>
              <ModalBody>
                Are you sure you want to delete this post? This action cannot be
                undone.
              </ModalBody>
              <ModalFooter>
                <Button color="default" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="danger" onPress={confirmDelete}>
                  Delete
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
