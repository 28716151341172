import React, { useState, useEffect } from "react";
import "./Settings.css";
import { Header } from "./components/Header";
import { Form, Input, Select } from "antd";
import { RubieButton } from "../../components/RubieButton/RubieButton";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../hooks/useAlert";
import WithQuery from "../../components/WithQuery";
import { CURRENT_USER_QUERY } from "../../graphql/user";
import { RubieUserService } from "../../services/RubieUser";
import { AMERICAN_STATES_AND_TERRITORIES } from "../../helpers/states";
import { goBack } from "../../helpers/navigation";

function SettingsLocationsPageInner({ data, loading, error }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const currentUser = data?.currentUser;

  useEffect(() => {
    if (currentUser) {
      form.setFieldsValue({
        currentCity: currentUser?.currentCity || "",
        currentState: currentUser?.currentState || "",
        currentFacility: currentUser?.currentFacility || "",
      });
    }
  }, [currentUser, form]);

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const { currentCity, currentState, currentFacility } = values;
      const result = await RubieUserService.updateLocation({
        currentCity,
        currentState,
        currentFacility,
      });

      if (result.errors?.length > 0) {
        showAlert("error", "Failed to update location. Please try again.");
      } else {
        showAlert("success", "Your location was updated!");
        goBack(navigate);
      }
    } catch (error) {
      console.error("Error updating location:", error);
      showAlert(
        "error",
        "An error occurred while updating location. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error loading user data</div>;

  return (
    <div className="SettingsContainer">
      <Header title="Locations" showTitle />
      <div style={{ border: "none" }} className="SettingsContent">
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={handleSubmit}
          style={{ maxWidth: 600, width: "100%" }}
          validateMessages={{ required: "Required field!" }}
          disabled={loading || isSubmitting}
        >
          <Form.Item
            name="currentCity"
            label="Current City"
            rules={[{ required: true }]}
          >
            <Input placeholder="e.g., San Francisco" size="large" />
          </Form.Item>
          <Form.Item
            name="currentState"
            label="Current State"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select a state"
              size="large"
              style={{ width: "100%" }}
            >
              {AMERICAN_STATES_AND_TERRITORIES.map((state) => (
                <Select.Option key={state.value} value={state.value}>
                  {state.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="currentFacility" label="Current Facility">
            <Input placeholder="Enter Current Facility" size="large" />
          </Form.Item>
          <div className="SettingsButtons">
            <RubieButton
              variant="ghost"
              onPress={() => navigate("/settings")}
              disabled={loading || isSubmitting}
            >
              Cancel
            </RubieButton>
            <RubieButton
              type="submit"
              loading={isSubmitting}
              disabled={loading}
            >
              Continue
            </RubieButton>
          </div>
        </Form>
      </div>
    </div>
  );
}

export function SettingsLocationsPage() {
  return WithQuery(
    SettingsLocationsPageInner,
    CURRENT_USER_QUERY,
    {},
    { refetchQueries: [{ query: CURRENT_USER_QUERY }] }
  )();
}
