import React, { useState } from "react";
import { Form } from "antd";
import { SignUpStep6 } from "./components/SignUpStep6";
import { useNavigate } from "react-router-dom";
import { SignUpHeader } from "./components/SignupHeader";
import { SignUpFooter } from "./components/SignupFooter";
import { SignUpStep1 } from "./components/SignupStep1";
import { SignUpStep2 } from "./components/SignupStep2";
import { SignUpStep3 } from "./components/SignupStep3";
import { SignUpStep4 } from "./components/SignupStep4";
import { SignUpLicenseNotFound } from "./components/SignUpLicenseNotFound";
import { SignUpStep5 } from "./components/SignupStep5";
import './Signup.css';

export function SignUp() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [step, setStep] = useState(1);
  const [user, setUser] = useState({});
  const [notFound, setNotFound] = useState(false);
  const [country, setCountry] = useState('United States');
  const [validateWith, setValidateWith] = useState();
  const [licenseType, setLicenseType] = useState();
  const [loadingNurse, setLoadingNurse] = useState();

  const handleFinish = (data) => {
    var removeItem = {};
    if (data.validateWith === 1) {
      removeItem.ncsbnId = undefined;
    } else if (data.validateWith === 2) {
      removeItem.licenseNumber = undefined;
      removeItem.licenseType = data.licenseType;
      removeItem.state = data.state;
    }
    setUser(user => ({ ...user, ...data, ...removeItem }));
    if (step !== 3) {
      setStep(step => step + 1);
    } else {
      searchNurse();
    }
  }

  // TODO(SAM): Connect to backend.
  const searchNurse = () => {
    setLoadingNurse(true);
    setTimeout(() => {
      setStep(step => step + 1)
      setLoadingNurse(false);
      // CHANGE IT
      setNotFound(Math.random() >= 0.5);
    }, 2000)
  }

  const resetForm = () => {
    setStep(step => step - 1);
    const newUser = { ...user };
    newUser.ncsbnId = undefined;
    newUser.licenseNumber = undefined;
    newUser.licenseType = undefined;
    newUser.state = undefined;
    setValidateWith(undefined);
  }

  const validateMessages = {
    required: 'This is required!',
    types: {
      email: 'This is not a valid email!',
    },
  };

  if (step === 6) {
    setTimeout(() => {
      navigate('/');
    }, 3000);

    return <SignUpStep6 />;
  }

  return (
    <Form
      layout="vertical"
      form={form}
      name="control-hooks"
      onFinish={handleFinish}
      style={{ maxWidth: 600 }}
      validateMessages={validateMessages}
    >
      <div style={{
        backgroundColor: '#FBFAF2',
        minWidth: '100vw',
        minHeight: '100vh',
        overflow: 'auto',
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: 'auto'
        }}>
          <SignUpHeader step={step} setStep={setStep} />
          <div style={{
            flex: 1
          }}>
            <div className='SignUpStepsContainer'>
              {step === 1 && <SignUpStep1 />}
              {step === 2 && <SignUpStep2 country={country} setCountry={setCountry} />}
              {step === 3 && (
                <SignUpStep3
                  form={form}
                  validateWith={validateWith}
                  setValidateWith={setValidateWith}
                  licenseType={licenseType}
                  setLicenseType={setLicenseType} />
              )}
              {(step === 4 && !notFound) && <SignUpStep4 resetForm={resetForm} user={user} />}
              {(step === 4 && notFound) && <SignUpLicenseNotFound />}
              {step === 5 && <SignUpStep5 />}
            </div>
          </div>
          <SignUpFooter loading={loadingNurse} notFound={step === 4 && notFound} resetForm={resetForm} />
        </div>
      </div>
    </Form>
  )
}