import RubieApolloClient from "./RubieApolloClient";
import {
  GET_CLINICAL_ROLES_QUERY,
  GET_CLINICAL_ROLE_TYPES_QUERY,
  GET_EMPLOYMENT_TYPES_QUERY,
  GET_SPECIALTIES_QUERY,
  UPDATE_USER_NAME_MUTATION,
  UPDATE_WORK_PROFILE_MUTATION,
  UPDATE_LOCATION_MUTATION,
  VERIFY_LICENSE_MUTATION,
} from "../graphql/user";

export const RubieUserService = {
  getClinicalRoles: async () => {
    try {
      const { data } = await RubieApolloClient.query({
        query: GET_CLINICAL_ROLES_QUERY,
      });
      return data.clinicalRoles;
    } catch (error) {
      console.error("Error fetching clinical roles:", error);
      throw error;
    }
  },

  getClinicalRoleTypes: async () => {
    try {
      const { data } = await RubieApolloClient.query({
        query: GET_CLINICAL_ROLE_TYPES_QUERY,
      });
      return data.clinicalRoleTypes;
    } catch (error) {
      console.error("Error fetching clinical role types:", error);
      throw error;
    }
  },

  getEmploymentTypes: async () => {
    try {
      const { data } = await RubieApolloClient.query({
        query: GET_EMPLOYMENT_TYPES_QUERY,
      });
      return data.employmentTypes;
    } catch (error) {
      console.error("Error fetching employment types:", error);
      throw error;
    }
  },

  getSpecialties: async () => {
    try {
      const { data } = await RubieApolloClient.query({
        query: GET_SPECIALTIES_QUERY,
      });
      return data.specialties;
    } catch (error) {
      console.error("Error fetching specialties:", error);
      throw error;
    }
  },

  updateUserName: async ({ firstName, lastName }) => {
    try {
      const { data } = await RubieApolloClient.mutate({
        mutation: UPDATE_USER_NAME_MUTATION,
        variables: { firstName, lastName },
      });
      return data.updateUserName;
    } catch (error) {
      console.error("Error updating user name:", error);
      throw error;
    }
  },

  updateWorkProfile: async ({
    specialty,
    clinicalRole,
    clinicalRoleType,
    employmentType,
    startedNursingDate,
  }) => {
    try {
      const { data } = await RubieApolloClient.mutate({
        mutation: UPDATE_WORK_PROFILE_MUTATION,
        variables: {
          specialty,
          clinicalRole,
          clinicalRoleType,
          employmentType,
          startedNursingDate,
        },
      });
      return data.updateWorkProfile;
    } catch (error) {
      console.error("Error updating work profile:", error);
      throw error;
    }
  },

  updateLocation: async ({ currentCity, currentState, currentFacility }) => {
    try {
      const { data } = await RubieApolloClient.mutate({
        mutation: UPDATE_LOCATION_MUTATION,
        variables: {
          currentCity,
          currentState,
          currentFacility,
        },
      });
      return data.updateLocation;
    } catch (error) {
      console.error("Error updating location:", error);
      throw error;
    }
  },

  verifyLicense: async ({
    licenseType,
    licenseNumber,
    licenseState,
    ncsbnId,
  }) => {
    try {
      console.log("licenseType", licenseType);
      console.log("licenseNumber", licenseNumber);
      console.log("licenseState", licenseState);
      console.log("ncsbnId", ncsbnId);
      const { data } = await RubieApolloClient.mutate({
        mutation: VERIFY_LICENSE_MUTATION,
        variables: {
          licenseType,
          licenseNumber,
          licenseState,
          ncsbnId,
        },
      });
      return data.verifyLicense;
    } catch (error) {
      console.error("Error verifying license:", error);
      throw error;
    }
  },
};
