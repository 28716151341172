import React, { useState } from 'react';
import './SignupHeader.css'
import { ReactComponent as CaretCircle } from '../../../assets/CaretCircle.svg';
import { useNavigate } from 'react-router-dom';
import { repeat } from 'lodash';

export function SignUpHeader({ step, setStep }) {
  const navigate = useNavigate();

  const [titleBackground] = useState(repeat(' • Create an account', 50));

  const back = () => {
    if (step === 1) {
      navigate('/');
    } else {
      setStep(x => x - 1);
    }
  }

  return (
    <div className='SignUpHeaderContainer'>
      <div className='SignUpHeaderCardHeaderContainer'>
        <div className='SignUpHeaderCardClose'>
          <div onClick={back}>
            <CaretCircle className='SignUpHeaderCaretLeft' /> BACK
          </div>
        </div>
        <div className='SignUpHeaderCardTitleDiv'>
          <div className='SignUpHeaderCardBackgroundTitle'>
            <span>
              {titleBackground}
            </span>
          </div>
        </div>
      </div>
      <div className='SignUpHeaderSteps'>
        <div className={`SignUpHeaderStep SignUpHeaderStepActived`}></div>
        <div className={`SignUpHeaderStep ${step > 1 ? 'SignUpHeaderStepActived' : ''}`}></div>
        <div className={`SignUpHeaderStep ${step > 2 ? 'SignUpHeaderStepActived' : ''}`}></div>
        <div className={`SignUpHeaderStep ${step > 3 ? 'SignUpHeaderStepActived' : ''}`}></div>
        <div className={`SignUpHeaderStep ${step > 4 ? 'SignUpHeaderStepActived' : ''}`}></div>
      </div>
    </div>
  )
}