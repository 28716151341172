import React from "react";
import "./Settings.css";
import { Header } from "./components/Header";
import { ConfigProvider, Switch } from "antd";
import { Link } from "react-router-dom";
import { USER } from "../../helpers/fakeData";

export function SettingsNotificationsPage() {

  return (
    <div className="SettingsContainer">
      <Header title='Notifications' showTitle />
      <ConfigProvider theme={{
        token: {
          colorPrimary: '#9AECBF',
          colorPrimaryHover: '#9AECBF',
          colorTextQuaternary: '#263322',
          colorPrimaryBorder: '#263322'
        },
      }}>
        <div style={{ border: 'none' }} className="SettingsContent">
          <div className="SettingsCards">
            <div className="SettingsCard">
              <div className="SettingsCardTitle">PUSH NOTIFICATIONS</div>
              <div className="SettingsCardItem">
                New posts
                <Switch defaultChecked={USER.notifications.newPosts} />
              </div>
              <div className="SettingsCardItem">
                New comments
                <Switch defaultChecked={USER.notifications.newComments} />
              </div>
              <div className="SettingsCardItem">
                New likes
                <Switch defaultChecked={USER.notifications.newLikes} />
              </div>
            </div>
            <div className="SettingsCard">
              <div className="SettingsCardTitle">EMAILS</div>
              <div className="SettingsCardItem">
                Promotional emails
                <Switch defaultChecked={USER.notifications.promotionalEmails} />
              </div>
              <div className="SettingsCardItem">
                Account updates
                <Switch defaultChecked={USER.notifications.accountUpdates} />
              </div>
            </div>
            <div className="SettingsCard">
              <div className="SettingsCardTitle">MESSAGING</div>
              <div className="SettingsCardDescription">When toggled ON, you agree to get messages, such as SMS, from Rubie to your mobile number and understand you don’t have to agree in order to use Rubie. Message and data rates may apply. Message frequency my vary. You can unsubscribe by texting STOP. See <Link>Privacy Policy</Link> and <Link>Terms of Service</Link>.</div>
              <div className="SettingsCardItem">
                Transactional Messages
                <Switch defaultChecked={USER.notifications.transactionalMessages} />
              </div>
              <div className="SettingsCardItem">
                Promotional Messages
                <Switch defaultChecked={USER.notifications.promotionalMessages} />
              </div>
            </div>
          </div>
        </div>
      </ConfigProvider>
    </div>
  )
}