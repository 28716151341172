import * as Sentry from "@sentry/react";

export const APP_NAME = "rubie-web";

export const APP_VERSION = process.env.REACT_APP_GIT_SHA || "";

export const IsProduction = () =>
  process.env.REACT_APP_VERCEL_ENV === "production" || false;

export const IsStaging = () =>
  process.env.REACT_APP_VERCEL_ENV === "preview" || false;

export const REACT_APP_STYTCH_PUBLIC_TOKEN =
  process.env.REACT_APP_STYTCH_PUBLIC_TOKEN || "";

export const STREAM_PUBLIC_API_KEY =
  process.env.REACT_APP_STREAM_PUBLIC_API_KEY || "";

export const STREAM_PUBLIC_APP_ID =
  process.env.REACT_APP_STREAM_PUBLIC_APP_ID || "";

// ---- Sentry

export const SENTRY_DSN_URL =
  "https://68590d2681bc7f10ac57c8673068461f@o4506912043827200.ingest.us.sentry.io/4507285541486592";

export const GetSentryEnvironment = () => {
  if (IsProduction()) {
    return "production";
  }
  // vercel preview
  if (IsStaging()) {
    return "staging";
  }

  return "development";
};

export const GetSentryReplaySessionSampleRate = () => {
  return IsProduction() ? 1.0 : 0.1;
};

export const GetSentryDebug = () => {
  return !IsProduction() && !IsStaging();
};

export const GetSentryEnabled = () => {
  return IsProduction() || IsStaging();
};

export const GetSentryConfig = () => {
  return {
    dsn: SENTRY_DSN_URL,

    enabled: GetSentryEnabled(),
    debug: GetSentryDebug(),
    environment: GetSentryEnvironment(),

    autoSessionTracking: true,
    release: APP_VERSION,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", StagingApiUrl, ProductionApiUrl],
    // Session Replay
    replaysSessionSampleRate: GetSentryReplaySessionSampleRate(),
    replaysOnErrorSampleRate: 1.0,
  };
};

// ----

export const ProductionAppUrl = "https://app.rubiehealth.com";
export const StagingAppUrl = "https://staging-app.rubiehealth.com";
export const DevelopmentAppUrl = "http://localhost:3006";

export const GetActiveAppUrl = () => {
  if (IsProduction()) {
    return ProductionAppUrl;
  }
  if (IsStaging()) {
    return StagingAppUrl;
  }
  return DevelopmentAppUrl;
};

export const ProductionApiUrl = "https://api.rubiehealth.com/graphql";
export const StagingApiUrl = "https://staging-api.rubiehealth.com/graphql";
export const DevelopmentApiUrl = "http://localhost:3009/graphql";

export const GetActiveAPIUrl = () => {
  if (IsProduction()) {
    return ProductionApiUrl;
  }
  if (IsStaging()) {
    return StagingApiUrl;
  }
  return DevelopmentApiUrl;
};

export const ProductLandingUrl = "https://rubiehealth.com";

export const PRIVACY_URL = "https://www.rubiehealth.com/privacy-policy";
export const TERMS_URL = "https://www.rubiehealth.com/terms-of-service";

export const GetServerRootDomain = () => {
  if (IsProduction() || IsStaging()) {
    return ".rubiehealth.com";
  }
  return "localhost";
};

export const GetStytchClientOptions = () => {
  return {
    cookieOptions: {
      availableToSubdomains: true,
      domain: GetServerRootDomain(),
    },
  };
};
