import React from "react";
import { Button } from "@nextui-org/react";
import { isNil } from "lodash";

import { useFeedContext, useStreamContext } from "react-activity-feed";

import { ReactComponent as Repost } from "../../assets/ArrowsClockwise.svg";
import { FEED_ID_USER } from "../../routes";

export default function RepostButton({
  activity,
  reaction,
  actor,
  hideRepostButton,
}) {
  const feed = useFeedContext();
  const { user } = useStreamContext();

  if (!reaction && !activity) return null;

  const reactionKind = "repost";

  const reaction_counts =
    reaction?.children_counts ?? activity?.reaction_counts;
  const repostCount = reaction_counts?.[reactionKind ?? ""] || 0;

  const ownReactions = reaction?.own_children ?? activity?.own_reactions;
  const ownRepost = ownReactions?.[reactionKind ?? ""]?.length;

  const handleRepostClick = () => {
    if (activity) return feed.onToggleReaction(reactionKind, activity, {}, {});

    return feed.onToggleChildReaction(reactionKind, reaction, {}, {});
  };

  // if actor is the current user, don't show the button
  if (actor.id === user.id) {
    return null;
  }

  if (!isNil(hideRepostButton)) {
    return null;
  }

  if (hideRepostButton) {
    return null;
  }

  return (
    <Button
      onClick={handleRepostClick}
      radius="none"
      color="transparent"
      className="border-r-1 border-black"
      startContent={<Repost fill={ownRepost ? "#F6C5FA" : "transparent"} />}
    >
      {repostCount}
    </Button>
  );
}
