import React, { useState } from "react";

import "./Invite.css";
import { Avatar } from "../Avatar";
import { formatRelativeTime } from "../../helpers/date";
import { RubieButton } from "../RubieButton";
import { ShareInviteModal } from "./ShareInviteModal";
import { ReactComponent as Share } from "../../assets/Export.svg";

export const TrackerInviteItem = ({ invite }) => {
  const [openShareModal, setOpenShareModal] = useState(false);

  return (
    <div className="TrackerInviteItemContainer">
      <Avatar
        name={invite.recipientName}
        backgroundColor="#FECDFE"
        size="medium"
      />
      <div className="TrackerInviteDescriptionContainer">
        <span className="TrackerInviteName">{invite.recipientName}</span>
        <span className="TrackerInviteDescription">
          {invite.acceptedAt
            ? `Accepted ${formatRelativeTime(new Date(invite.acceptedAt))}`
            : `Invite sent ${formatRelativeTime(new Date(invite.createdAt))}`}
        </span>
        {invite.expiresAt && (
          <span className="TrackerInviteExpiration">
            Expires {formatRelativeTime(new Date(invite.expiresAt))}
          </span>
        )}
      </div>
      {!invite.acceptedAt && (
        <RubieButton
          onPress={() => setOpenShareModal(true)}
          size="sm"
          className="ml-auto self-center"
        >
          <Share size={16} />
        </RubieButton>
      )}
      <ShareInviteModal
        setOpenDrawer={setOpenShareModal}
        openDrawer={openShareModal}
        invite={invite}
      />
    </div>
  );
};
