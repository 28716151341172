export const TOPIC_ID_LIFESTYLE = "lifestyle";
export const TOPIC_ID_CAREER = "career";
export const TOPIC_ID_BREAKROOM = "breakroom";

export const FEED_TOPIC_TITLES = {
  [TOPIC_ID_LIFESTYLE]: "Lifestyle",
  [TOPIC_ID_CAREER]: "Career",
  [TOPIC_ID_BREAKROOM]: "Breakroom",
};

export const FEED_TOPIC_DESCRIPTIONS = {
  [TOPIC_ID_LIFESTYLE]: "From travel tales to pet adventures, hobbies, and well-being tips.",
  [TOPIC_ID_CAREER]: "Professional growth, tips, advice.and real-world experiences.",
  [TOPIC_ID_BREAKROOM]: "Off-topic and just for fun! Unwind, laugh, and talk about anything but work.",
};