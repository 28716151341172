import React, { useState } from "react";
import "./Header.css";
import { ReactComponent as CaretCircle } from "../../../assets/CaretCircle.svg";
import { useNavigate } from "react-router-dom";
import { repeat } from "lodash";
import { goBack } from "../../../helpers/navigation";

export function Header({ title, backButtonPath, showTitle }) {
  const navigate = useNavigate();

  const [titleBackground] = useState(repeat(" • " + title, 50));

  const back = () => {
    backButtonPath ? navigate(backButtonPath) : goBackActions();
  };

  const goBackActions = () => {
    goBack(navigate);
  };

  return (
    <div className="SettingsHeaderContainer">
      <div
        className="SettingsHeaderCardHeaderContainer"
        style={{ height: showTitle ? "126px" : "88px" }}
      >
        <div className="SettingsHeaderCardClose">
          <div onClick={back}>
            <CaretCircle className="SettingsHeaderCaretLeft" /> BACK
          </div>
        </div>
        <div className="SettingsHeaderCardTitleDiv">
          {showTitle && (
            <div className="SettingsTitle">
              <span>{title}</span>
            </div>
          )}
          <div className="SettingsHeaderCardBackgroundTitle">
            <span>{titleBackground}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
