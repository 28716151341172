import { create } from 'zustand'

export const useCommentStore = create((set, get) => ({
  comments: {},
  setComments: (commentId, text) => {
    const currentComments = get().comments;
    currentComments[commentId] = text;

    set({
      comments: currentComments
    })
  },
  clearComments: () => {
    set({
      comments: {}
    })
  }
}));
