import React from 'react';
import globe from '../../../assets/globe.svg';
import { Form, Input, Select } from 'antd';

export function SignUpStep2({ country, setCountry }) {
  return (
    <div className='SignUpStep'>
      <img width={40} className='SignUpStepImg' src={globe} alt='Globe' />
      <div className='SignUpStepTitle'>Where Are You Located?</div>
      <div className='SignUpStepDescription'>Your location helps us connect you with local resources and events. </div>
      <Form.Item extra={<div className='SignUpStepInfo'>Currently, Rubie is available only for nurses in the US. Stay tuned for future updates!</div>}
        name="country" label="Country" initialValue='United States' rules={[{ required: true }]}>
        <Select options={[{
          value: 'United States',
          label: 'United States',
        }]}
          value={country}
          onChange={setCountry}
          placeholder="Select Country"
          size='large'
          defaultValue={'United States'}
          disabled
        />
      </Form.Item>
      <Form.Item name="cityState" label="City and State" rules={[{ required: true }]}>
        <Input disabled={!country} size='large' placeholder="e.g., San Francisco, CA" />
      </Form.Item>
    </div>
  )
}