import React from "react";
import "./AccountVerifyFooter.css";
import { RubieButton } from "../../../components/RubieButton";

import { Link } from "@nextui-org/react";
import { PRIVACY_URL, TERMS_URL } from "../../../helpers/config";

export function AccountVerifyFooter({ loading, step }) {
  return (
    <div className="AccountVerifyFooterContainer">
      <RubieButton loading={loading} type="submit" style={{ width: "100%" }}>
        Continue
      </RubieButton>
      {step === 1 ? (
        <div className="AccountVerifyFooterText">
          By continuing you agree to Rubie’s{" "}
          <Link href={PRIVACY_URL} target="_blank">
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link href={TERMS_URL} target="_blank">
            Terms & Conditions.
          </Link>
        </div>
      ) : null}

      <div className="AccountVerifyFooterText">
        Have questions or need help? Email us at{" "}
        <Link href="mailto:support@rubiehealth.com">
          support@rubiehealth.com
        </Link>
      </div>
    </div>
  );
}
