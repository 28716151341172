import { isNil } from "lodash";

export function fullName(user) {
  if (isNil(user)) return "";
  return user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName;
}

export function getInitialLetters(name) {
  const fullNameSplitted = name?.split(" ");
  return getLetters(fullNameSplitted);
}

export function getFirstAndLastName(name) {
  if (isNil(name)) return "";

  const nameArr = name?.split(" ");
  if (nameArr.length === 1) {
    return nameArr[0];
  }
  return `${nameArr[0]} ${nameArr[nameArr.length - 1]}`;
}

function getLetters(nameArr) {
  if (isNil(nameArr)) return "";
  if (nameArr.length === 1) {
    return nameArr[0][0];
  }
  return `${nameArr[0][0]}${nameArr[nameArr.length - 1][0]}`;
}
