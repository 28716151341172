import React from 'react';
import { ReactComponent as SealCheck } from '../../../assets/sealCheck.svg';

export function SignUpStep6() {
  return (
    <div className='SignUpStep6'>
      <div>
        <SealCheck />
        Welcome to Rubie
      </div>
      <div>
        &nbsp;
      </div>
    </div>
  )
}