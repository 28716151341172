import React from "react";

import { AuthenticatedLayout } from "../../layoutsHOC/AuthenticatedLayout";

import { useNavigate } from "react-router-dom";
import { CreatePostHeader } from "../../components/CreatePost/CreatePostHeader";

import { ReactComponent as Construction } from "../../assets/Construction.svg";
import { ReactComponent as EmojiSilence } from "../../assets/EmojiSilence.svg";
import { ReactComponent as EmojiDoctor } from "../../assets/EmojiDoctor.svg";
import { ReactComponent as EmojiBrain } from "../../assets/EmojiBrain.svg";
import { ReactComponent as EmojiRedFlag } from "../../assets/EmojiRedFlag.svg";
import { ReactComponent as CaretRight } from "../../assets/CaretRight.svg";

import "./Guidelines.css";
import {
  GUIDELINES_COMMUNITY_MEMBERS,
  GUIDELINES_INTELLECTUAL_PROPERTY,
  GUIDELINES_PATIENT_PRIVACY,
  GUIDELINES_VIOLATE_COMMUNITY_GUIDELINES,
} from "../../routes";

function CommunityGuidelinesInner() {
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate("/", { replace: true });
  };

  return (
    <div style={{ maxWidth: "720px", width: "100%" }}>
      <CreatePostHeader
        color={"#E2DEC6"}
        backgroundTitle={"#FAF7E8"}
        title={"Community Guidelines"}
        backgroundAction={"#FAF7E8"}
        onCloseAction={navigateBack}
      />
      <div className="flex flex-col pt-4 pb-4 gap-4 pl-4 pr-4">
        <Construction />
        <span className="GuidelinesTitle">
          Learn about the Rubie Community Guidelines
        </span>
        <span className="GuidelinesDescription">
          To ensure a positive and respectful environment for everyone, please
          follow these simple guidelines. Together, we can create a supportive
          and engaging space for all our members.
        </span>
      </div>
      <div className="flex flex-col pt-4 pb-4 gap-4 pl-4 pr-4">
        <GuidelineItem
          icon={<EmojiSilence />}
          text={"Respect Patient Privacy"}
          onClick={() => navigate(GUIDELINES_PATIENT_PRIVACY)}
        />
        <GuidelineItem
          icon={<EmojiDoctor />}
          text={"Respect Your Fellow Community Members"}
          onClick={() => navigate(GUIDELINES_COMMUNITY_MEMBERS)}
        />
        <GuidelineItem
          icon={<EmojiBrain />}
          text={"Acknowledge and Respect Intellectual Property"}
          onClick={() => navigate(GUIDELINES_INTELLECTUAL_PROPERTY)}
        />
        <GuidelineItem
          icon={<EmojiRedFlag />}
          text={"What Happens If I Violate a Community Guideline?"}
          onClick={() => navigate(GUIDELINES_VIOLATE_COMMUNITY_GUIDELINES)}
        />
      </div>
    </div>
  );
}

const GuidelineItem = ({ icon, text, onClick }) => {
  return (
    <div className="GuidelineItem" onClick={onClick}>
      <div className="flex flex-row gap-4 items-center">
        {icon}
        {text}
      </div>
      <CaretRight />
    </div>
  );
};

export default function CommunityGuidelines(props) {
  return (
    <AuthenticatedLayout
      {...props}
      backgroundColor="#FBFDFE"
      hideHeader={true}
      hideFooter={true}
    >
      <CommunityGuidelinesInner {...props} />
    </AuthenticatedLayout>
  );
}
