import React from "react";
import { Button } from "@nextui-org/button";

import "./RubieButton.css";

export function RubieButton({ onPress, size, color, style, type, loading, variant, className, disabled, children }) {
  return (
    <Button
      onPress={onPress}
      style={style}
      color={color}
      size={size}
      variant={variant}
      type={type}
      isLoading={loading}
      disabled={disabled}
      className={`RubieButton ${className}`}
    >
      {children}
    </Button>
  );
}
